'use client';

import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from 'src/lib/utils';
import { Button } from 'src/app/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'src/app/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'src/app/components/ui/popover';

export interface ComboboxOption {
  value: string;
  label: string;
}

export interface ComboboxProps {
  options: ComboboxOption[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  emptyMessage?: string;
  className?: string;
}

export function Combobox({
  options,
  value,
  onChange,
  placeholder = 'Select option...',
  searchPlaceholder = 'Search...',
  emptyMessage = 'No option found.',
  className,
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false);
  const [buttonWidth, setButtonWidth] = React.useState<number>(0);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          ref={(n) => {
            setButtonWidth(n?.offsetWidth ?? 0);
          }}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            'tw-w-full tw-justify-between !tw-border-input hover:!tw-bg-gray-light/50',

            className
          )}
        >
          <span className={cn(value ? '!tw-text-primary-dark' : '!tw-text-muted-foreground')}>
            {value
              ? options.find((option) => String(option.value) === String(value))?.label
              : placeholder}
          </span>
          <ChevronsUpDown className="tw-ml-2 tw-h-4 tw-w-4 tw-shrink-0 tw-opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="!tw-p-0" style={{ width: `${buttonWidth}px` }}>
        <Command>
          <CommandInput placeholder={searchPlaceholder} />
          <CommandList>
            <CommandEmpty>{emptyMessage}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={(currentValue) => {
                    onChange(currentValue === value ? '' : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'tw-mr-2 tw-h-4 tw-w-4',
                      value === option.value ? 'tw-opacity-100' : 'tw-opacity-0'
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
