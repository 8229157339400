import React, { useEffect, useState } from 'react';
import { Button } from 'src/app/components/ui/button';
import { BrowserWebcam } from 'src/app/modules/candidate/AiInterviews/BrowserWebcam';
import { useATS } from './context/ATSContext';
import { TimerReset } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { useCountdown } from 'src/app/hooks/useCountdown';
import { getQuestionAudio, saveATSInterviewVideo } from 'src/app/api/ats/service';
import CancelConfirmationDialog from './cancel-confirmation-dialog';
import { QUESTION_TIMER } from '../candidate/AiInterviews/constants';
import LoadingScreen from './loading-screen';
import { Skeleton } from 'src/app/components/ui/skeleton';

const Interview = () => {
  const [isSaving, setIsSaving] = useState(false);
  const {
    token,
    currentQuestion,
    handleQuestionAnswered,
    questionsAnswered,
    nextQuestion,
    interview,
  } = useATS();
  const { start, timeDisplay, isComplete, reset, stop, isWarning } = useCountdown({
    minutes: 3,
    seconds: 0,
    warningThresholdSec: 30,
  });
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (isSaving) {
      stop();
    }
  }, [isSaving]);

  useEffect(() => {
    const isAnswered = !!interview?.questions[currentQuestion - 1].answer;
    if (isAnswered) {
      nextQuestion();
    }
    // Reset and cleanup previous audio
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }

    // Get and play audio for current question
    if (interview?.questions[currentQuestion - 1]) {
      const question = interview.questions[currentQuestion - 1].question;
      getQuestionAudio(question)
        .then((newAudio) => {
          setAudio(newAudio);
          newAudio.play().catch((e) => console.error('Error playing audio:', e));
        })
        .catch((e) => console.error('Error fetching audio:', e));
    }

    // Start timer after audio setup
    start();

    // Cleanup function
    return () => {
      if (audio) {
        audio.pause();
        URL.revokeObjectURL(audio.src);
      }
    };
  }, [currentQuestion, interview]);

  useEffect(() => {
    if (isComplete) {
      handleNext();
    }
  }, [isComplete]);

  const handleStopRecording = () => {
    document.getElementById('stop-recording-btn')?.click();
  };

  const handleNext = () => {
    setIsSaving(true);
    handleStopRecording();
  };

  const questionId = interview?.questions[currentQuestion - 1].questionId;
  const questionTitle = interview?.questions[currentQuestion - 1].questionTitle;
  const question = interview?.questions[currentQuestion - 1].question;

  const length = interview?.questions.length ?? 1;

  const handleSaveVideo: (file: File, transcript: string) => Promise<boolean> = async (
    file,
    transcript
  ) => {
    if (!questionId || !token) {
      return false;
    }

    const isLastQuestion = currentQuestion === interview?.questions.length;

    try {
      if (isLastQuestion) {
        // For last question, await the save operation
        await saveATSInterviewVideo({
          questionId,
          file,
          token,
          transcription: transcript,
          interviewType: 'ats',
        });
      } else {
        // For other questions, save in background
        saveATSInterviewVideo({
          questionId,
          file,
          token,
          transcription: transcript,
          interviewType: 'ats',
        }).catch((e) => console.error('Error saving video:', e));
      }

      setIsSaving(false);
      handleQuestionAnswered(currentQuestion - 1, true);
      nextQuestion();
      reset();
      return true;
    } catch (error) {
      console.error('Error saving video:', error);
      setIsSaving(false);
      return false;
    }
  };

  return (
    <>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-[1.4fr_1fr] tw-gap-4">
        <div className="tw-flex tw-flex-col tw-gap-3">
          <h1 className="tw-text-h1 tw-h-[33px] tw-flex tw-items-end tw-mb-0 tw-font-extrabold tw-text-left tw-w-fit">
            {questionTitle}
          </h1>
          <div className="tw-flex tw-h-[25px] tw-w-full tw-items-center tw-px-2 tw-justify-between">
            <div className="tw-flex tw-items-center tw-gap-2.5">
              <TimerReset className="tw-stroke-gray-light2 -tw-scale-x-100 tw-size-6" />
              <div className="tw-flex tw-gap-0.5 tw-items-center">
                <span
                  className={cn(
                    'tw-text-priBlack tw-text-lg tw-font-semibold mr-1 tw-w-[4ch] tw-animate-pulse',
                    isWarning && 'tw-text-red-500 '
                  )}
                >
                  {timeDisplay}
                </span>
                <span className="tw-text-priBlack tw-text-xs tw-font-semibold">Sec</span>
                <span className="tw-text-pri tw-text-xs tw-font-normal">/ Left</span>
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2.5">
              <div className="tw-text-sm tw-font-medium tw-text-priBlack">Progress</div>
              <ul className="tw-flex relative tw-gap-2">
                {interview?.questions.map((question, index) => (
                  <li
                    key={question.questionId}
                    className={cn(`tw-w-8 tw-h-[5px] tw-rounded-full`, {
                      'tw-bg-pri': questionsAnswered[index],
                      'tw-bg-gray-light': index >= currentQuestion - 1,
                    })}
                  ></li>
                ))}
                <li
                  className={cn(
                    'tw-w-8 tw-h-[5px] tw-rounded-full',
                    'tw-absolute tw-top-0 tw-transition-all tw-duration-300'
                  )}
                  style={{
                    left: `calc(${((currentQuestion - 1) / length) * 100}% + ${
                      (currentQuestion - 1) * 0.07
                    }rem)`,
                  }}
                >
                  <div
                    className={cn(
                      `tw-w-8 tw-h-[5px] tw-bg-pri tw-animate-pulse tw-rounded-full 'tw-bg-pri'`
                    )}
                  ></div>
                </li>
              </ul>
            </div>
          </div>
          <div className="tw-flex-grow">
            <BrowserWebcam
              isPreview={true}
              isPractice={false}
              onCancel={() => {}}
              onSave={handleSaveVideo}
              maxDurationSeconds={QUESTION_TIMER}
              setTimerSeconds={() => {}}
              setTimerStarted={() => {}}
              autoClose={true}
            />
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-3">
          <div className="tw-text-h1 tw-h-[33px] tw-mb-0 tw-font-extrabold tw-text-left tw-w-full"></div>
          <div className="tw-flex tw-h-[25px] tw-text-pri tw-font-medium tw-flex-grow-0 tw-w-full tw-items-center tw-justify-between">
            AI Interviewer
          </div>
          <div className="tw-flex-grow tw-rounded-lg">
            <div className="tw-relative h-full tw-border tw-border-pri/50 tw-rounded-lg">
              <img
                src="/media/rebeca.png"
                alt="Rebeca"
                className="w-[95px] h-[95px] absolute right-4 tw-border-2 tw-border-pri/40 shadow-xs top-0 -translate-y-1/2 rounded-3xl"
                id="ai-interviewer-camera"
              />
              <div className="tw-text-gray-light2 tw-font-medium tw-px-6 tw-mt-8">
                Being reviewed by Rebeca
              </div>
              <div className="tw-text-priBlack tw-leading-normal tw-text-3xl tw-font-semibold tw-mt-2 tw-px-6 tw-mb-0 tw-text-left tw-w-full">
                {isSaving ? (
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    {[75, 85, 65, 80, 70, 60].map((width, index) => (
                      <Skeleton
                        key={index}
                        className={`tw-h-[45px]`}
                        style={{ width: `${width}%` }}
                      />
                    ))}
                  </div>
                ) : (
                  question
                )}
              </div>
            </div>
          </div>
          <div className="tw-grid tw-flex-grow-0 tw-grid-cols-3 tw-gap-2">
            <CancelConfirmationDialog disabled={isSaving} />
            <Button disabled={isSaving} onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
      {(!interview || isSaving) && <LoadingScreen />}
    </>
  );
};

export default Interview;
