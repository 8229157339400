import React, { useRef } from 'react';
import { Button } from 'src/app/components/ui/button';
import { useATS } from './context/ATSContext';
import LoadingScreen from './loading-screen';
import { Input } from 'src/app/components/ui/input';
import { cn } from 'src/lib/utils';
// import ResumePreview from './resume-viewer';

export const PreviewResume = () => {
  const resumeRef = useRef<HTMLInputElement>(null);
  const {
    navigateToPreviewUser,
    handleResumeUpload,
    handleSkipResumeUpload,
    isLoading,
    resume,
    candidate,
    setResume,
  } = useATS();

  const handleCancel = async () => {
    navigateToPreviewUser();
  };

  const handleUpload = async () => {
    resumeRef.current?.click();
  };

  return (
    <>
      <div className="tw-grid tw-grid-cols-1 tw-gap-4 tw-min-h-[calc(100vh-3.5rem)] ">
        <div className="tw-flex tw-flex-col tw-gap-3">
          <h1 className="tw-text-h1 tw-h-[33px] tw-flex tw-items-end tw-mb-0 tw-font-extrabold tw-text-left tw-w-fit">
            Upload Resume
          </h1>
          <div className="tw-flex tw-w-full tw-flex-col tw-h-full tw-flex-1 tw-items-center tw-px-2 tw-justify-center">
            <Input
              type="file"
              ref={resumeRef}
              accept=".pdf,.doc,.docx"
              onChange={(e) => setResume(e.target.files?.[0] || null)}
              className="tw-hidden"
            />
            <div
              className={cn(
                'tw-min-h-[36px] tw-min-w-[250px] tw-max-w-[30ch] tw-mb-6 tw-text-lg tw-flex tw-items-center tw-font-bold tw-justify-center tw-text-black tw-border tw-border-dashed tw-border-primary tw-rounded-md tw-p-2',
                {
                  'tw-opacity-0': !resume,
                }
              )}
            >
              {resume?.name && resume.name.length > 30
                ? `...${resume.name.slice(-30)}`
                : resume?.name}
            </div>
            <div className="tw-flex tw-flex-col tw-w-[250px] tw-justify-center tw-gap-[16px]">
              {candidate?.resume_url ? (
                <Button onClick={handleSkipResumeUpload}>Continue with Existing Resume</Button>
              ) : null}
              <Button disabled={!resume} onClick={handleResumeUpload}>
                Save And Continue
              </Button>
              <Button variant="outline" onClick={handleUpload}>
                Upload New CV
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel Interview
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingScreen className="!tw-bg-white" />}
    </>
  );
};

export default PreviewResume;
