import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'src/app/modules/auth';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  profileFormSchema,
  filterProfileFormData,
  ProfileFormValues,
  checkProfileCompletion,
} from './profile.schema';
import { CountryOptions } from 'src/app/util/countries';
import { StateOptions, TimezoneOptions } from 'src/app/util/timezone';
import { SalaryRanges } from 'src/app/util/salary';
import { startAvailabityOptions } from 'src/app/util/startAvailabilityOptions';
import { YearsExperience } from 'src/app/util/yearsexperience';
import { ComboboxOption } from 'src/app/components/ui/combobox';
import {
  deleteProfileImage,
  parseResume,
  updateCandidateProfile,
  updateProfileImage,
  uploadCandidateResume,
} from 'src/app/api/candidate/profile';
import { useToast } from 'src/app/hooks/use-toast';
import dayjs from 'dayjs';
import jobFormSchema, { mapWorkFormDataToApi, mapApiDataToWorkForm } from './work.schema';
import { JobFormData } from './work.schema';
import { CandidateProfileModel } from '../Models';
import { useSidebar } from 'src/app/components/ui/sidebar';
import {
  addCandidateWorkHistory,
  deleteCandidateWorkHistory,
  updateCandidateWorkHistory,
} from 'src/app/api/candidate/work-history';
import {
  EducationFormData,
  mapApiDataToEducationForm,
  mapEducationFormDataToApi,
} from './education.schema';
import educationFormSchema from './education.schema';
import {
  addCandidateEducation,
  deleteCandidateEducation,
  updateCandidateEducation,
} from 'src/app/api/candidate/education';
import certificationFormSchema, {
  mapApiDataToCertificationForm,
  mapCertificationFormDataToApi,
} from './certification.schema';
import { CertificationFormData } from './certification.schema';
import {
  addCandidateCertificate,
  deleteCandidateCertificate,
  updateCandidateCertificate,
} from 'src/app/api/candidate/certificate';
interface Step {
  title: string;
  description: string;
}

const countries = CountryOptions();
// {
//     "value": "Argentina",
//     "label": "🇦🇷 Argentina",
//     "flag": "🇦🇷",
//     "flags": [
//         "https://flagcdn.com/ar.svg",
//         "https://flagcdn.com/w320/ar.png"
//     ],
//     "timezones": [
//         "UTC-03:00"
//     ],
//     "region": "Americas"
// }
const states = StateOptions.map((state) => ({
  label: state,
  value: state,
}));
const timezones = TimezoneOptions(false).map((timezone) => ({
  label: timezone.label,
  value: `${timezone.label} (${timezone.value})`,
  dbValue: timezone.value,
}));
const formatTimezoneDisplay = (timezoneValue: string): string => {
  const timezone = TimezoneOptions(false).find((tz) => tz.value === timezoneValue);
  return timezone ? `${timezone.label} (${timezone.value})` : timezoneValue;
};
const extractTimezoneValue = (formattedTimezone: string): string => {
  const matches = formattedTimezone.match(/\(([^()]*)\)$/);
  return matches ? matches[matches.length - 1] : formattedTimezone;
};

const salaryRanges = SalaryRanges;
const startAvailabilities = startAvailabityOptions;
const yearsOfExperience = YearsExperience as unknown as ComboboxOption[];
export interface EditUserContextType {
  profilePercentage: number;
  currentStep: number;
  steps: Step[];
  stepState: {
    isCompleted: boolean;
  }[];
  form: UseFormReturn<any>;
  workForm: UseFormReturn<JobFormData>;
  countries: typeof countries;
  isUS: boolean;
  setIsUS: (isUS: boolean) => void;
  states: typeof states;
  timezones: typeof timezones;
  salaryRanges: typeof salaryRanges;
  startAvailabilities: typeof startAvailabilities;
  yearsOfExperience: typeof yearsOfExperience;
  handleResumeUpload: (file: File) => Promise<void>;
  handleProfileSubmit: (profileData: ProfileFormValues) => Promise<void>;
  imageUrl: string | null;
  handleImageUpload: (file: File) => Promise<void>;
  handleWorkSubmit: (workData: JobFormData, type: 'add' | 'update') => Promise<void>;
  handleWorkDelete: (id: string) => Promise<void>;
  profile?: CandidateProfileModel;
  missingFields: string[];
  isLoading: boolean;
  removeImage: () => Promise<void>;
  educationForm: UseFormReturn<EducationFormData>;
  handleEducationSubmit: (
    educationData: EducationFormData,
    type: 'add' | 'update'
  ) => Promise<void>;
  handleEducationDelete: (id: string) => Promise<void>;
  certificationForm: UseFormReturn<CertificationFormData>;
  handleCertificationSubmit: (
    certificationData: CertificationFormData,
    type: 'add' | 'update'
  ) => Promise<void>;
  handleCertificationDelete: (id: string) => Promise<void>;
  workHistory: JobFormData[];
  educationHistory: EducationFormData[];
  certifications: CertificationFormData[];
  defaultWorkHistory: JobFormData;
  defaultEducationHistory: EducationFormData;
  defaultCertificationHistory: CertificationFormData;
  selected: string | null;
  setSelected: (selected: string | null) => void;
  defaultImage: boolean;
}

interface EditUserProviderProps {
  children: ReactNode;
}

const steps = [
  {
    title: 'Setup Profile',
    description: 'Setup your profile to get started',
  },
  {
    title: 'Work History & Education',
    description: 'Add your work experience and education to your profile',
  },
  {
    title: 'Interview',
    description: 'Add your interview to your profile',
  },
];

export const SCORES = {
  basicInfo: {
    name: 10,
    avatar: 10,
    experience: 5,
    availability: 5,
    timezone: 10,
    salaryRange: 5,
    headline: 10,
    bio: 10,
    languages: 5,
  },
  skills: {
    technical: 5,
    additional: 5,
  },
  location: {
    base: 5,
    details: 5,
  },
  history: {
    work: 5,
    education: 5,
  },
} as const;

export const isDefaultProfileImageRegex =
  /https:\/\/distro-app-assets\.s3\.amazonaws\.com\/avatars\/profile_\d+\.svg/;

const defaultWorkHistory: JobFormData = {
  title: '',
  companyName: '',
  isCurrentlyWorking: false,
  startDate: {
    month: dayjs().add(1, 'month').month(),
    year: dayjs().subtract(1, 'year').year(),
  },
  endDate: {
    month: dayjs().month(),
    year: dayjs().year(),
  },
  description: '',
};

const defaultEducationHistory: EducationFormData = {
  institution: '',
  degree: '',
  startDate: {
    month: dayjs().add(1, 'month').month(),
    year: dayjs().subtract(1, 'year').year(),
  },
  endDate: {
    month: dayjs().month(),
    year: dayjs().year(),
  },
  description: '',
};

const defaultCertificationHistory: CertificationFormData = {
  name: '',
  certificateId: '',
  dateAcquired: {
    month: dayjs().month(),
    year: dayjs().year(),
  },
};
export const EditUserContext = createContext<EditUserContextType>({
  profilePercentage: 0,
  currentStep: 1,
  steps: [],
  stepState: [],
  form: {} as UseFormReturn<any>,
  workForm: {} as UseFormReturn<JobFormData>,
  countries,
  isUS: false,
  setIsUS: () => {},
  states,
  timezones,
  salaryRanges,
  startAvailabilities,
  yearsOfExperience,
  handleResumeUpload: async () => {},
  handleProfileSubmit: async () => {},
  imageUrl: null,
  handleImageUpload: async () => {},
  handleWorkSubmit: async () => {},
  handleWorkDelete: async (id: string) => {},
  profile: undefined,
  missingFields: [],
  isLoading: true,
  removeImage: async () => {},
  educationForm: {} as UseFormReturn<EducationFormData>,
  handleEducationSubmit: async () => {},
  handleEducationDelete: async (id: string) => {},
  certificationForm: {} as UseFormReturn<CertificationFormData>,
  handleCertificationSubmit: async () => {},
  handleCertificationDelete: async (id: string) => {},
  workHistory: [],
  educationHistory: [],
  certifications: [],
  defaultWorkHistory,
  defaultEducationHistory,
  defaultCertificationHistory,
  selected: null,
  setSelected: () => {},
  defaultImage: true,
});

const mergeWorkHistory = (
  newItems: JobFormData | JobFormData[],
  existingItems: JobFormData[]
): JobFormData[] => {
  const itemsToAdd = Array.isArray(newItems) ? newItems : [newItems];
  const result = [...existingItems];

  itemsToAdd.forEach((item) => {
    // Set endDate to today's date if currently working
    const endDate = item.isCurrentlyWorking
      ? new Date()
      : new Date(
          item.endDate?.year ?? new Date().getFullYear(),
          item.endDate?.month ?? new Date().getMonth()
        );
    const startDate = new Date(item.startDate.year, item.startDate.month);

    let insertIndex = result.findIndex((existing) => {
      const existingEndDate = existing.isCurrentlyWorking
        ? new Date()
        : new Date(
            existing.endDate?.year ?? new Date().getFullYear(),
            existing.endDate?.month ?? new Date().getMonth()
          );
      const existingStartDate = new Date(existing.startDate.year, existing.startDate.month);

      // Sort by endDate first, then by startDate
      if (endDate > existingEndDate) return true;
      if (endDate < existingEndDate) return false;
      return startDate > existingStartDate;
    });

    if (insertIndex === -1) {
      insertIndex = result.length;
    }

    result.splice(insertIndex, 0, item);
  });

  return result;
};

const mergeEducationHistory = (
  newItems: EducationFormData | EducationFormData[],
  existingItems: EducationFormData[]
): EducationFormData[] => {
  const itemsToAdd = Array.isArray(newItems) ? newItems : [newItems];
  const result = [...existingItems];

  itemsToAdd.forEach((item) => {
    const endDate = item.isCurrentlyStudying
      ? new Date()
      : new Date(
          item.endDate?.year ?? new Date().getFullYear(),
          item.endDate?.month ?? new Date().getMonth()
        );
    const startDate = new Date(item.startDate.year, item.startDate.month);

    let insertIndex = result.findIndex((existing) => {
      const existingEndDate = existing.isCurrentlyStudying
        ? new Date()
        : new Date(
            existing.endDate?.year ?? new Date().getFullYear(),
            existing.endDate?.month ?? new Date().getMonth()
          );
      const existingStartDate = new Date(existing.startDate.year, existing.startDate.month);

      // Sort by endDate first, then by startDate
      if (endDate > existingEndDate) return true;
      if (endDate < existingEndDate) return false;
      return startDate > existingStartDate;
    });

    if (insertIndex === -1) {
      insertIndex = result.length;
    }

    result.splice(insertIndex, 0, item);
  });

  return result;
};

const mergeCertificationHistory = (
  newItems: CertificationFormData | CertificationFormData[],
  existingItems: CertificationFormData[]
): CertificationFormData[] => {
  const itemsToAdd = Array.isArray(newItems) ? newItems : [newItems];
  const result = [...existingItems];

  // Separate items with and without dates
  const itemsWithDates: CertificationFormData[] = [];
  const itemsWithoutDates: CertificationFormData[] = [];

  itemsToAdd.forEach((item) => {
    if (item.dateAcquired?.year && item.dateAcquired?.month !== undefined) {
      itemsWithDates.push(item);
    } else {
      itemsWithoutDates.push(item);
    }
  });

  // Insert items with dates in sorted order
  itemsWithDates.forEach((item) => {
    const dateAcquired = new Date(item.dateAcquired!.year, item.dateAcquired!.month);
    let insertIndex = result.findIndex((existing) => {
      if (!existing.dateAcquired?.year || existing.dateAcquired?.month === undefined) return false;
      const existingDate = new Date(existing.dateAcquired.year, existing.dateAcquired.month);
      return dateAcquired > existingDate;
    });

    if (insertIndex === -1) {
      insertIndex = result.length;
    }

    result.splice(insertIndex, 0, item);
  });

  // Append items without dates at the end
  return [...result, ...itemsWithoutDates];
};

export const EditUserProvider = ({ children }: EditUserProviderProps) => {
  const { profile: candidateProfile, fetchCandidateProfile, currentUser } = useAuth();
  const { remainingWidthStyle } = useSidebar();

  const [workHistory, setWorkHistory] = useState<JobFormData[]>([]);
  const [educationHistory, setEducationHistory] = useState<EducationFormData[]>([]);
  const [certifications, setCertifications] = useState<CertificationFormData[]>([]);
  const [isUS, setIsUS] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [resume, setResume] = useState<File | null>(null);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [selected, setSelected] = useState<string | null>(null);
  const [stepState, setStepState] = useState<{ isCompleted: boolean; onClick: () => void }[]>([
    {
      isCompleted: false,
      onClick: () => {
        setCurrentStep(1);
      },
    },
    {
      isCompleted: false,
      onClick: () => {
        setCurrentStep(2);
      },
    },
    {
      isCompleted: false,
      onClick: () => {
        setCurrentStep(3);
      },
    },
  ]);

  const { toast } = useToast();
  const form = useForm({
    resolver: zodResolver(profileFormSchema),
  });

  const workForm = useForm<JobFormData>({
    resolver: zodResolver(jobFormSchema),
    defaultValues: defaultWorkHistory,
  });

  const educationForm = useForm<EducationFormData>({
    resolver: zodResolver(educationFormSchema),
    defaultValues: defaultEducationHistory,
  });

  const certificationForm = useForm<CertificationFormData>({
    resolver: zodResolver(certificationFormSchema),
    defaultValues: defaultCertificationHistory,
  });

  const updateWorkHistory = (data: JobFormData | JobFormData[], isReset = false) => {
    setWorkHistory(mergeWorkHistory(data, isReset ? [] : workHistory));
  };

  const updateEducationHistory = (
    data: EducationFormData | EducationFormData[],
    isReset = false
  ) => {
    setEducationHistory(mergeEducationHistory(data, isReset ? [] : educationHistory));
  };

  const updateCertificationHistory = (
    data: CertificationFormData | CertificationFormData[],
    isReset = false
  ) => {
    setCertifications(mergeCertificationHistory(data, isReset ? [] : certifications));
  };

  const profilePercentage = useMemo(() => {
    let score = 0;
    const missingFields: string[] = [];

    // Use form values for personal information
    const formData = form.getValues();

    // Basic Information
    if (formData.full_name) {
      score += SCORES.basicInfo.name;
    } else {
      missingFields.push('First Name and Last Name');
    }

    if (!isDefaultProfileImageRegex.test(imageUrl ?? '')) {
      score += SCORES.basicInfo.avatar;
    } else {
      missingFields.push('Profile Picture');
    }

    if (formData.years_professional_experience) {
      score += SCORES.basicInfo.experience;
    } else {
      missingFields.push('Years of Experience');
    }

    if (formData.start_availability) {
      score += SCORES.basicInfo.availability;
    } else {
      missingFields.push('Start Availability');
    }

    if (formData.timezone) {
      score += SCORES.basicInfo.timezone;
    } else {
      missingFields.push('Timezone');
    }

    if (formData.salary_range_in_cents) {
      score += SCORES.basicInfo.salaryRange;
    } else {
      missingFields.push('Salary Range');
    }

    if (formData.headline) {
      score += SCORES.basicInfo.headline;
    } else {
      missingFields.push('Headline');
    }

    if (formData.bio) {
      score += SCORES.basicInfo.bio;
    } else {
      missingFields.push('Bio');
    }

    if (formData.languages) {
      score += SCORES.basicInfo.languages;
    } else {
      missingFields.push('Languages');
    }

    // Skills
    if (formData.skills?.length && formData.additional_skills?.length) {
      score += SCORES.skills.technical + SCORES.skills.additional;
    } else {
      missingFields.push('Technical and Additional Skills');
    }

    // Location
    if (formData.location) {
      score += SCORES.location.base;
      if (['US', 'United States'].includes(formData.location)) {
        if (formData.state && formData.city) {
          score += SCORES.location.details;
        } else {
          missingFields.push('State and City');
        }
      } else {
        score += SCORES.location.details;
      }
    } else {
      missingFields.push('Location');
    }

    // Use local states for history
    if (workHistory.length) {
      score += SCORES.history.work;
    } else {
      missingFields.push('Work History');
    }

    if (educationHistory.length) {
      score += SCORES.history.education;
    } else {
      missingFields.push('Education History');
    }

    setMissingFields(missingFields);
    setIsLoading(false);
    return score;
  }, [form, workHistory, educationHistory, certifications]);

  useEffect(() => {
    const data = candidateProfile;
    if (data) {
      setImageUrl(data.avatar_url);
      const newData = {
        ...data,
        location: data.location_by_country,
        timezone: data.timezone ? formatTimezoneDisplay(data.timezone) : undefined,
      };
      form.reset(filterProfileFormData(newData));
      const workHistory = data.work_history ?? [];
      const educationHistory = data.education ?? [];
      const certifications = data.certifications ?? [];
      updateWorkHistory(workHistory.map(mapApiDataToWorkForm), true);
      updateEducationHistory(educationHistory.map(mapApiDataToEducationForm), true);
      updateCertificationHistory(certifications.map(mapApiDataToCertificationForm), true);
    }
  }, [candidateProfile, form]);

  useEffect(() => {
    const profileCompletion = checkProfileCompletion(form.getValues());

    setStepState([
      {
        isCompleted:
          profileCompletion.isComplete && !isDefaultProfileImageRegex.test(imageUrl ?? ''),
        onClick: () => {
          setCurrentStep(1);
        },
      },
      {
        isCompleted: workHistory.length > 0 && educationHistory.length > 0,
        onClick: () => {
          setCurrentStep(2);
        },
      },
      {
        isCompleted: !!currentUser?.profile_status?.completion_status?.vet_ai,
        onClick: () => {
          setCurrentStep(3);
        },
      },
    ]);
  }, [form, imageUrl, workHistory, educationHistory, currentUser]);

  const handleResumeUpload = async (file: File) => {
    setResume(file);
    try {
      setIsLoading(true);
      const response = await parseResume(file);
      console.log('parseResume', response);
      toast({
        title: 'Resume uploaded successfully',
        description: 'Your resume has been uploaded successfully',
      });
    } catch (error) {
      toast({
        title: 'Error uploading resume',
        description: 'Your resume has not been uploaded',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = async (file: File) => {
    try {
      setIsLoading(true);
      const newImageUrl = await updateProfileImage(file);
      if (newImageUrl) {
        await fetchCandidateProfile();
        setImageUrl(newImageUrl);
        toast({
          title: 'Image uploaded successfully',
          description: 'Your profile image has been uploaded successfully',
        });
      } else {
        toast({
          title: 'Error uploading image',
          description: 'Your profile image has not been updated',
          variant: 'destructive',
        });
      }
    } catch (error) {
      toast({
        title: 'Error uploading image',
        description: 'Your profile image has not been updated',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const removeImage = async () => {
    try {
      setIsLoading(true);
      await deleteProfileImage();
      await fetchCandidateProfile();
      setImageUrl(null);
      toast({
        title: 'Image removed successfully',
        description: 'Your profile image has been removed successfully',
      });
    } catch (error) {
      toast({
        title: 'Error removing image',
        description: 'Your profile image has not been removed',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfileSubmit = async (profileData: ProfileFormValues) => {
    profileData.timezone = extractTimezoneValue(profileData.timezone);
    try {
      setIsLoading(true);
      await updateCandidateProfile(profileData);
      toast({
        title: 'Profile updated successfully',
        description: 'Your profile has been updated successfully',
      });
    } catch (error) {
      toast({
        title: 'Error updating profile',
        description: 'Your profile has not been updated',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleWorkSubmit = async (workData: JobFormData, type: 'add' | 'update') => {
    try {
      setIsLoading(true);
      const data = mapWorkFormDataToApi(workData);
      if (type === 'add') {
        const resData = await addCandidateWorkHistory(data);
        const cleanData = mapApiDataToWorkForm(resData);
        updateWorkHistory(cleanData);
      } else {
        const resData = await updateCandidateWorkHistory(data);
        const cleanData = mapApiDataToWorkForm(resData);
        setWorkHistory(
          mergeWorkHistory(
            cleanData,
            workHistory.filter((d) => d.id !== workData.id)
          )
        );
      }
      workForm.reset(defaultWorkHistory);
      setSelected(null);
      toast({
        title: `${type === 'add' ? 'Work history created' : 'Work history updated'} successfully`,
        description: `Your work history has been ${
          type === 'add' ? 'added' : 'updated'
        } successfully`,
      });
    } catch (error) {
      toast({
        title: `${type === 'add' ? 'Error creating work history' : 'Error updating work history'}`,
        description: `Your work history has not been ${type === 'add' ? 'added' : 'updated'}`,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleWorkDelete = async (id: string) => {
    try {
      setIsLoading(true);
      await deleteCandidateWorkHistory(id);
      setWorkHistory(workHistory.filter((d) => d.id !== id));
      toast({
        title: 'Work history deleted successfully',
        description: 'Your work history has been deleted successfully',
      });
    } catch (error) {
      toast({
        title: 'Error deleting work history',
        description: 'Your work history has not been deleted',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEducationSubmit = async (
    educationData: EducationFormData,
    type: 'add' | 'update'
  ) => {
    try {
      setIsLoading(true);
      const data = mapEducationFormDataToApi(educationData);
      if (type === 'add') {
        const resData = await addCandidateEducation(data);
        const cleanData = mapApiDataToEducationForm(resData);
        updateEducationHistory(cleanData);
      } else {
        const resData = await updateCandidateEducation(data);
        const cleanData = mapApiDataToEducationForm(resData);
        setEducationHistory(
          mergeEducationHistory(
            cleanData,
            educationHistory.filter((d) => d.id !== educationData.id)
          )
        );
      }
      educationForm.reset(defaultEducationHistory);
      setSelected(null);
      toast({
        title: `${type === 'add' ? 'Education created' : 'Education updated'} successfully`,
        description: `Your education has been ${type === 'add' ? 'added' : 'updated'} successfully`,
      });
    } catch (error) {
      toast({
        title: `${type === 'add' ? 'Error creating education' : 'Error updating education'}`,
        description: `Your education has not been ${type === 'add' ? 'added' : 'updated'}`,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEducationDelete = async (id: string) => {
    try {
      setIsLoading(true);
      await deleteCandidateEducation(id);
      setEducationHistory(educationHistory.filter((d) => d.id !== id));
      toast({
        title: 'Education deleted successfully',
        description: 'Your education has been deleted successfully',
      });
    } catch (error) {
      toast({
        title: 'Error deleting education',
        description: 'Your education has not been deleted',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCertificationSubmit = async (
    certificationData: CertificationFormData,
    type: 'add' | 'update'
  ) => {
    try {
      setIsLoading(true);
      const data = mapCertificationFormDataToApi(certificationData);
      if (type === 'add') {
        const resData = await addCandidateCertificate(data);
        const cleanData = mapApiDataToCertificationForm(resData);
        updateCertificationHistory(cleanData);
      } else {
        const resData = await updateCandidateCertificate(data);
        const cleanData = mapApiDataToCertificationForm(resData);
        setCertifications(
          mergeCertificationHistory(
            cleanData,
            certifications.filter((d) => d.id !== certificationData.id)
          )
        );
      }
      certificationForm.reset(defaultCertificationHistory);
      setSelected(null);
      toast({
        title: `${type === 'add' ? 'Certification created' : 'Certification updated'} successfully`,
        description: `Your certification has been ${
          type === 'add' ? 'added' : 'updated'
        } successfully`,
      });
    } catch (error) {
      toast({
        title: `${
          type === 'add' ? 'Error creating certification' : 'Error updating certification'
        }`,
        description: `Your certification has not been ${type === 'add' ? 'added' : 'updated'}`,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCertificationDelete = async (id: string) => {
    try {
      setIsLoading(true);
      await deleteCandidateCertificate(id);
      setCertifications(certifications.filter((d) => d.id !== id));
      toast({
        title: 'Certification deleted successfully',
        description: 'Your certification has been deleted successfully',
      });
    } catch (error) {
      toast({
        title: 'Error deleting certification',
        description: 'Your certification has not been deleted',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      fetchCandidateProfile();
    };
  }, []);

  return (
    <EditUserContext.Provider
      value={{
        profile: candidateProfile,
        profilePercentage,
        currentStep,
        steps,
        form,
        workForm,
        countries,
        isUS,
        setIsUS,
        states,
        timezones,
        salaryRanges,
        startAvailabilities,
        yearsOfExperience,
        handleResumeUpload,
        handleProfileSubmit,
        stepState,
        imageUrl,
        handleImageUpload,
        handleWorkSubmit,
        handleWorkDelete,
        missingFields,
        isLoading,
        removeImage,
        educationForm,
        handleEducationSubmit,
        handleEducationDelete,
        certificationForm,
        handleCertificationSubmit,
        handleCertificationDelete,
        workHistory,
        educationHistory,
        certifications,
        defaultWorkHistory,
        defaultEducationHistory,
        defaultCertificationHistory,
        selected,
        setSelected,
        defaultImage: isDefaultProfileImageRegex.test(imageUrl ?? ''),
      }}
    >
      {children}
      {isLoading && (
        <div className="tw-fixed tw-z-[60] tw-h-screen tw-w-screen tw-inset-0 tw-flex tw-items-start tw-justify-end tw-cursor-not-allowed">
          <div
            style={{ backdropFilter: 'blur(1.5px)', width: remainingWidthStyle }}
            className="tw-fixed tw-right-0 tw-h-screen tw-top-0 tw-bottom-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-white/50 tw-cursor-default"
          >
            <img
              src="/media/logos/logo_dark_prpl.svg"
              alt="Distro"
              className="-tw-ml-[7px] tw-w-[160px]"
            />
            <div className="tw-size-16 tw-flex tw-items-center tw-justify-center tw-mt-4">
              <div className=" tw-scale-150">
                <div className="loader-1">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </EditUserContext.Provider>
  );
};

export const useEditUserContext = () => {
  return useContext(EditUserContext);
};
