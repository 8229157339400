import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LayoutSplashScreen } from '../../../../_theme/layout/core';
import { AuthModel, UserModel, UserTypeEnum } from './_models';
import * as authHelper from './AuthHelpers';
import { getUserByToken, logoutUser } from './_requests';
import { WithChildren } from '../../../../_theme/helpers';
import { usePostHog } from 'posthog-js/react';
import { Config } from 'src/config';
import axios from 'axios';
import { CandidateProfileModel } from '../../candidate/profile/Models';

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  clearAndSetAuth: (auth: AuthModel) => void;
  currentUser: UserModel | undefined;
  profile?: CandidateProfileModel;
  fetchCandidateProfile: () => Promise<CandidateProfileModel | undefined>;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
  accountType: undefined | UserTypeEnum.CANDIDATE | UserTypeEnum.CUSTOMER;
  roles: undefined | Array<string>;
  setAccountType: Dispatch<
    SetStateAction<UserTypeEnum.CANDIDATE | UserTypeEnum.CUSTOMER | undefined>
  >;
  setSuperAdmin: Dispatch<SetStateAction<boolean>>;
  isSuperAdmin: boolean;
  isCustomer: boolean;
  isApplicant: boolean;
  isCandidate: boolean;
  isOwner: undefined | boolean;
  canCreateJob: undefined | boolean;
  impersonateOrganization: (organization_id: string, auth: AuthModel) => void;
  // isAdmin: undefined | boolean;
  // isManager: undefined | boolean;
  // isBilling: undefined | boolean;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  clearAndSetAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  impersonateOrganization: () => {},
  profile: undefined,
  fetchCandidateProfile: () => Promise.resolve(undefined),
  accountType: authHelper.getAuth()?.user.user_type,
  roles: authHelper.getAuth()?.user.roles,
  setAccountType: () => {},
  setSuperAdmin: () => {},
  isSuperAdmin: authHelper.getAuth()?.user.superadmin == true,
  isCustomer: authHelper.getAuth()?.user.user_type == UserTypeEnum.CUSTOMER,
  isCandidate: authHelper.getAuth()?.user.user_type == UserTypeEnum.CANDIDATE,
  isApplicant: authHelper.getAuth()?.user.is_applicant == true,
  isOwner: authHelper.getAuth()?.user.roles?.includes('owner'),
  canCreateJob:
    authHelper.getAuth()?.user.roles?.includes('owner') ||
    authHelper.getAuth()?.user.roles?.includes('admin') ||
    authHelper.getAuth()?.user.roles?.includes('manager'),
  // isAdmin: authHelper.getAuth()?.user.roles?.includes('admin'),
  // isManager: authHelper.getAuth()?.user.roles?.includes('manager'),
  // isBilling: authHelper.getAuth()?.user.roles?.includes('billing')
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const posthog = usePostHog();
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(auth?.user);
  const [candidateProfile, setCandidateProfile] = useState<CandidateProfileModel | undefined>(
    undefined
  );
  const [accountType, setAccountType] = useState<
    undefined | UserTypeEnum.CANDIDATE | UserTypeEnum.CUSTOMER
  >(auth?.user.user_type);
  const [isSuperAdmin, setSuperAdmin] = useState<boolean>(false);
  const [isApplicant, setIsApplicat] = useState<boolean>(false);
  const isCustomer = accountType === UserTypeEnum.CUSTOMER;
  const isCandidate = accountType === UserTypeEnum.CANDIDATE;
  const roles = auth?.user.roles;
  const isOwner = auth?.user.roles?.includes('owner');
  const canCreateJob =
    auth?.user.roles?.includes('owner') ||
    auth?.user.roles?.includes('admin') ||
    auth?.user.roles?.includes('manager');
  const impersonateOrganization = (org_id: string, auth: AuthModel) => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    setAccountType(undefined);
    authHelper.removeAuth();
    saveAuth(auth);
  };
  const clearAndSetAuth = (auth: AuthModel) => {
    authHelper.clearAndSetAuth(auth);
    setCurrentUser(auth.user);
  };
  const saveAuth = (auth: AuthModel | undefined) => {
    console.log('auth', auth);
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
      setCurrentUser(auth.user);
      setAccountType(auth.user.user_type);
      setSuperAdmin(auth.user.superadmin);
      setIsApplicat(auth.user.is_applicant);
      posthog?.identify(auth.user.id, {
        email: auth.user.email,
        name: `${auth.user.first_name} ${auth.user.last_name}`,
      });
      posthog?.group('company', auth.user.organization_id as string);
      if (auth.user.organization_id) {
        window.Appcues.group(auth.user.organization_name?.toLowerCase(), {
          company_name: auth.user.organization_name,
          company_id: auth.user.organization_id,
        });
      }
      window.Appcues.identify(auth.user.id, {
        user_type: auth.user.user_type,
        name: `${auth.user.first_name} ${auth.user.last_name}`,
        email: auth.user.email,
        companyName: auth.user.organization_name,
      });
    } else {
      window.Appcues.reset();
      authHelper.removeAuth();
      setAuth(undefined);
    }
  };

  const logout = async () => {
    if (currentUser) {
      try {
        await logoutUser();
      } catch (e) {}
    }
    posthog?.reset(true);

    window.Appcues.reset();
    saveAuth(undefined);
    setCurrentUser(undefined);
    setSuperAdmin(false);
    setAccountType(undefined);
    authHelper.removeAuth();
  };

  const fetchCandidateProfile = async (): Promise<CandidateProfileModel | undefined> => {
    const response = await axios.get(`${Config.api_url}/candidate/profile`);
    setCandidateProfile(response.data.value);
    return response.data.value;
  };

  useEffect(() => {
    if (auth && auth?.user.user_type === UserTypeEnum.CANDIDATE) {
      fetchCandidateProfile();
    }
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        setSuperAdmin,
        clearAndSetAuth,
        currentUser,
        isSuperAdmin,
        setCurrentUser,
        logout,
        roles,
        isOwner,
        canCreateJob,
        isCustomer,
        isCandidate,
        isApplicant,
        accountType,
        setAccountType,
        impersonateOrganization,
        profile: candidateProfile,
        fetchCandidateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, setSuperAdmin, setAccountType } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data }: any = await getUserByToken();
          if (data) {
            setSuperAdmin(data.value.user.superadmin);
            setAccountType(data.value.user.user_type);
            setCurrentUser(data.value.user);
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (auth && auth.access_token) {
      requestUser(auth.access_token);
    } else {
      logout();
      setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
