import * as z from 'zod';

export const profileFormSchema = z
  .object({
    full_name: z.string().min(1, 'Full name is required'),

    skills: z
      .array(z.string())
      .min(1, 'At least one main skill is required')
      .max(5, 'Maximum 5 main skills allowed'),

    additional_skills: z
      .array(z.string())
      .min(1, 'At least one additional skill is required')
      .max(20, 'Maximum 20 additional skills allowed'),

    years_professional_experience: z.string().min(1, 'Years of experience is required'),

    start_availability: z.string().min(1, 'Start availability is required'),
    location: z.string().min(1, 'Country is required'),

    // Conditional validation for US locations
    state: z.string().optional(),
    city: z.string().optional(),

    timezone: z.string().min(1, 'Timezone is required'),

    salary_range_in_cents: z.string().min(1, 'Salary range is required'),

    headline: z
      .string()
      .min(1, 'Headline is required')
      .max(30, 'Headline must be 30 characters or less'),

    bio: z.string().min(1, 'Bio is required'),

    languages: z.array(z.string()).min(1, 'At least one language is required'),
  })
  .superRefine((data, ctx) => {
    if (data.location === 'United States' && !data.state) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'State is required for US locations',
        path: ['state'],
      });
    }
    if (data.location === 'United States' && !data.city) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'City is required for US locations',
        path: ['city'],
      });
    }
  });

export type ProfileFormValues = z.infer<typeof profileFormSchema>;

export const filterProfileFormData = (data: Record<string, any>): Partial<ProfileFormValues> => {
  const schemaKeys = Object.keys((profileFormSchema._def.schema as any).shape);
  const values = Object.fromEntries(
    Object.entries(data).filter(
      ([key, value]) => schemaKeys.includes(key) && value !== undefined && value !== null
    )
  );
  return values;
};

export interface CompletionStatus {
  isComplete: boolean;
  missingFields: string[];
}

export const checkProfileCompletion = (data: Record<string, any> | null): CompletionStatus => {
  if (!data) {
    return { isComplete: false, missingFields: ['All profile data'] };
  }

  // Filter the data to only include fields defined in the schema
  const filteredData = filterProfileFormData(data);

  // Validate against the schema
  const result = profileFormSchema.safeParse(filteredData);

  if (result.success) {
    return { isComplete: true, missingFields: [] };
  }

  // Extract and format error messages from Zod validation
  const missingFields = result.error.issues.map((issue) => {
    // Get the field path
    const field = issue.path.join('.');

    // Format the error message to be more user-friendly
    switch (field) {
      case 'full_name':
        return 'Full Name';
      case 'skills':
        return 'Main Skills';
      case 'additional_skills':
        return 'Additional Skills';
      case 'years_professional_experience':
        return 'Years of Experience';
      case 'start_availability':
        return 'Start Availability';
      case 'location':
        return 'Country';
      case 'state':
        return 'State';
      case 'city':
        return 'City';
      case 'timezone':
        return 'Timezone';
      case 'salary_range_in_cents':
        return 'Salary Range';
      case 'headline':
        return 'Headline';
      case 'bio':
        return 'Bio';
      case 'languages':
        return 'Languages';
      default:
        return issue.message || field;
    }
  });

  return {
    isComplete: false,
    missingFields: Array.from(new Set(missingFields)), // Convert Set to Array
  };
};
