import { candidateAPI } from '.';
import { WorkHistory } from './types';
export const getCandidateWorkHistory = async () => {
  const response = await candidateAPI.get('/work-history');
  return response.data.value;
};

export const addCandidateWorkHistory = async (data: any): Promise<WorkHistory> => {
  const response = await candidateAPI.post('/work-history', data);
  const responseData = response.data.value[0];
  const workHistory: WorkHistory = {
    id: responseData.id,
    title: responseData.title,
    description: responseData.description,
    company_name: responseData.company_name,
    start_date: responseData.started_at,
    end_date: responseData.ended_at,
    is_current: responseData.is_current,
  };
  return workHistory;
};

export const updateCandidateWorkHistory = async (data: any): Promise<WorkHistory> => {
  const response = await candidateAPI.put(`/work-history/${data.id}`, data);
  const responseData = response.data.value[0];
  const workHistory: WorkHistory = {
    id: responseData.id,
    title: responseData.title,
    description: responseData.description,
    company_name: responseData.company_name,
    start_date: responseData.started_at,
    end_date: responseData.ended_at,
    is_current: responseData.is_current,
  };
  return workHistory;
};

export const deleteCandidateWorkHistory = async (id: string) => {
  const response = await candidateAPI.delete(`/work-history/${id}`);
  return response.data.value;
};
