import { candidateAPI } from '.';
import { Education } from './types';
export const getCandidateEducation = async () => {
  const response = await candidateAPI.get('/education');
  return response.data.value;
};

export const addCandidateEducation = async (data: any): Promise<Education> => {
  const response = await candidateAPI.post('/education', data);
  const responseData = response.data.value[0];
  const education: Education = {
    id: responseData.id,
    institution: responseData.institution,
    degree: responseData.degree,
    description: responseData.description,
    start_date: responseData.started_at,
    end_date: responseData.ended_at,
    is_current: responseData.is_current,
  };
  return education;
};

export const updateCandidateEducation = async (data: any): Promise<Education> => {
  const response = await candidateAPI.put(`/education/${data.id}`, data);
  const responseData = response.data.value[0];
  const education: Education = {
    id: responseData.id,
    institution: responseData.institution,
    degree: responseData.degree,
    description: responseData.description,
    start_date: responseData.started_at,
    end_date: responseData.ended_at,
    is_current: responseData.is_current,
  };
  return education;
};

export const deleteCandidateEducation = async (id: string) => {
  const response = await candidateAPI.delete(`/education/${id}`);
  return response.data.value;
};
