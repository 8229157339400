import { UserAvatar } from './user-avatar';

interface PageHeaderProps {
  title: string;
  description: string | React.ReactNode;
  avatar?: string;
  name?: string;
  initials?: string;
}

export function PageHeader({ title, description, avatar, name, initials }: PageHeaderProps) {
  const renderDescription = () => {
    if (typeof description === 'string') {
      return <p className="tw-text-sm tw-text-primary-black">{description}</p>;
    }
    return description;
  };

  return (
    <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full">
      <div>
        <h1 className="tw-text-xl tw-font-extrabold tw-text-primary-black">{title}</h1>
        {renderDescription()}
      </div>
      <div className="tw-flex tw-flex-row tw-gap-2">
        <UserAvatar src={avatar} alt={name} initials={initials} size={44} />
      </div>
    </div>
  );
}
