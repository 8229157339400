import { Mail, PartyPopper, Pin } from 'lucide-react';

const Complete = () => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-6">
      <div className="tw-bg-white tw-rounded-2xl tw-p-8 tw-max-w-lg tw-text-center">
        <div className="tw-flex tw-justify-center tw-mb-4">
          <div className="tw-relative">
            <svg
              className="tw-w-16 tw-h-16 tw-text-primary"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path>
            </svg>
            <div className="tw-absolute tw-right-0 -tw-top-4 tw-h-full tw-animate-progress-pulse tw-bg-white -tw-rotate-45"></div>
          </div>
        </div>

        <h1 className="tw-text-2xl tw-flex tw-items-center tw-justify-center tw-font-bold tw-text-gray-800">
          Interview Submitted! <PartyPopper className="tw-w-7 tw-h-7 tw-ml-2 tw-text-primary" />
        </h1>
        <p className="tw-text-gray-600 tw-mt-2">
          Thank you for completing your interview! Your responses have been successfully submitted
          and are now being reviewed.
        </p>

        <div className="tw-mt-6 tw-text-left">
          <p className="tw-text-lg tw-font-semibold tw-flex tw-items-center tw-text-gray-800">
            <Pin className="tw-w-5 tw-h-5 tw-mr-1 tw-text-primary tw-rotate-45" /> What happens
            next?
          </p>
          <ul className="tw-list-disc tw-list-inside tw-text-gray-600 tw-mt-1">
            <li>Your interview is currently being analyzed.</li>
            <li>
              You'll receive feedback within the next{' '}
              <strong className="tw-text-gray-900">24 hours</strong> via email.
            </li>
          </ul>
        </div>

        <div className="tw-mt-6 tw-text-left">
          <p className="tw-text-lg tw-font-semibold tw-text-gray-800 tw-flex tw-items-center">
            <Mail className="tw-w-[18px] tw-h-[18px] tw-mr-1 tw-text-primary" /> Need assistance?
          </p>
          <p className="tw-text-gray-600">
            If you don't receive an update within the expected timeframe, feel free to contact us at{' '}
            <a
              href="mailto:support@distro.io"
              className="tw-text-primary tw-font-medium hover:tw-underline"
            >
              support@distro.io
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Complete;
