import React from 'react';
import ResumeParser from './resume-parser';
import Stepper from './stepper';
import { PageHeader } from 'src/app/components/common/page-header';
import EditUserContent from './setup-profile';
import { EditUserProvider, useEditUserContext } from './context';
import { Tabs, TabsContent, TabsList } from 'src/app/components/ui/tabs';
import WorkHistoryAndEducation from './work-history';
import Interview from './interview';
import { Skeleton } from 'src/app/components/ui/skeleton';

const EditProfilePageBase = () => {
  const {
    isLoading,
    steps,
    currentStep,
    profilePercentage,
    stepState,
    profile: candidateProfile,
  } = useEditUserContext();
  const description = (
    <div className="tw-text-sm tw-relative tw-space-x-1">
      <span className="tw-text-primary">{profilePercentage}%</span>
      <span>Complete!</span>
      {isLoading && (
        <div className="tw-absolute tw-inset-0">
          <Skeleton className="tw-w-full tw-h-full" />
        </div>
      )}
    </div>
  );
  return (
    <Tabs value={steps[currentStep - 1].title}>
      <PageHeader
        title="Profile Update"
        description={description}
        avatar={candidateProfile?.avatar_url}
        name={`${candidateProfile?.first_name} ${candidateProfile?.last_name}`}
        initials={`${candidateProfile?.first_name?.charAt(0) || ''}${
          candidateProfile?.last_name?.charAt(0) || ''
        }`}
      />
      {/* <div className="tw-h-5"></div> */}
      {/* <ResumeParser /> */}
      <TabsList className="tw-w-full">
        <Stepper steps={steps} currentStep={currentStep} stepState={stepState} />
      </TabsList>
      <TabsContent value={steps[0].title} className="tw-pt-6">
        <EditUserContent />
      </TabsContent>
      <TabsContent value={steps[1].title} className="tw-pt-6">
        <WorkHistoryAndEducation />
      </TabsContent>
      <TabsContent value={steps[2].title} className="tw-pt-6">
        <Interview />
      </TabsContent>
    </Tabs>
  );
};

const EditProfilePage = () => {
  return (
    <EditUserProvider>
      <EditProfilePageBase />
    </EditUserProvider>
  );
};
export default EditProfilePage;
