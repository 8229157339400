import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutSplashScreen } from '../_theme/layout/core';
import { MasterInit } from '../_theme/layout/MasterInit';
import { AuthInit } from './modules/auth';
import Hotjar from '@hotjar/browser';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  Hotjar.init(5235963, 6);
  console.info('Hotjar initialized');
}

const BlockMobile = () => {
  return (
    <div className="md:hidden fixed inset-0 flex items-center justify-center bg-gray-800 text-white text-center p-4 z-50">
      <div>
        <h1 className="text-2xl font-bold mb-4 text-white">App Not Available on Mobile</h1>
        <p className="text-lg">
          Please use a desktop or a larger screen to access this application.
        </p>
      </div>
    </div>
  );
};

export default BlockMobile;

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BlockMobile />
      <AuthInit>
        <Outlet />
        <MasterInit />
      </AuthInit>
    </Suspense>
  );
};

export { App };
