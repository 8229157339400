import { FC } from 'react';
import { Button } from 'src/app/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/app/components/ui/form';
import { Input } from 'src/app/components/ui/input';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'src/app/components/ui/sheet';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/app/components/ui/select';
import dayjs from 'dayjs';
import { useEditUserContext } from './context';
import { CertificationFormData } from './certification.schema';

const months = Array.from({ length: 12 }, (_, index) => ({
  value: `${index + 1}`,
  label: dayjs().month(index).format('MMMM'),
}));

const years = Array.from({ length: 40 }, (_, index) => ({
  value: `${dayjs().subtract(index, 'year').year()}`,
  label: dayjs().subtract(index, 'year').year(),
}));

interface CertificationHistoryFormProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  type?: 'add' | 'update';
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const CertificationHistoryForm: FC<CertificationHistoryFormProps> = ({
  children,
  className,
  type = 'add',
  onClick,
  open,
  onOpenChange,
}) => {
  const { certificationForm: form, handleCertificationSubmit } = useEditUserContext();

  const addCertificationHistory = async (data: CertificationFormData) => {
    await handleCertificationSubmit(data, type);
  };

  return (
    <div>
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetTrigger className={className} onClick={onClick} asChild>
          {children}
        </SheetTrigger>
        <SheetContent className="!tw-w-1/3 !tw-max-w-[500px]">
          <SheetHeader>
            <SheetTitle>Add Certification</SheetTitle>
            <SheetDescription>Add your professional certification details</SheetDescription>
          </SheetHeader>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(addCertificationHistory)}
              className="tw-flex tw-flex-col tw-gap-4 tw-pt-4"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <div>
                      <FormLabel className="required">Certification Name</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="AWS Certified Solutions Architect" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="certificateId"
                render={({ field }) => (
                  <FormItem>
                    <div>
                      <FormLabel>Certificate ID</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="ABC123XYZ" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="dateAcquired"
                render={({ field }) => (
                  <FormItem>
                    <div>
                      <FormLabel className="required">Date Acquired</FormLabel>
                      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                        <div className="tw-flex tw-flex-col">
                          <FormDescription>Month</FormDescription>
                          <Select
                            onValueChange={(value) => {
                              field.onChange({ ...field.value, month: Number(value) });
                            }}
                            value={field.value?.month?.toString() ?? ''}
                          >
                            <FormControl>
                              <SelectTrigger value={field.value?.month?.toString() ?? ''}>
                                <SelectValue placeholder="Month" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {months.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="tw-flex tw-flex-col">
                          <FormDescription>Year</FormDescription>
                          <Select
                            onValueChange={(value) => {
                              field.onChange({ ...field.value, year: Number(value) });
                            }}
                            value={field.value?.year?.toString() ?? ''}
                          >
                            <FormControl>
                              <SelectTrigger value={field.value?.year?.toString() ?? ''}>
                                <SelectValue placeholder="Year" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {years.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <FormMessage />
                      </div>
                    </div>
                  </FormItem>
                )}
              />

              <SheetFooter className="!tw-justify-start">
                <Button type="submit">Save changes</Button>
              </SheetFooter>
            </form>
          </Form>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default CertificationHistoryForm;
