import { Link, useLocation } from 'react-router-dom';
import { ChevronRight, Fingerprint } from 'lucide-react';
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from 'src/app/components/ui/sidebar';
import { checkIsActive } from 'src/_theme/helpers';
import { useEffect } from 'react';
import { useState } from 'react';

export function NavSuperAdmin() {
  const { pathname } = useLocation();
  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    const saved = localStorage.getItem('impersonated_org');
    let org_name = null;
    if (saved) {
      org_name = JSON.parse(saved);
    }

    setOrgName(org_name);
  }, []);
  return (
    <SidebarMenu className="tw-px-2">
      <SidebarMenuItem>
        <SidebarMenuButton size="lg" asChild className="tw-h-12">
          <Link
            to="/superadmin"
            data-active={checkIsActive(pathname, '/superadmin')}
            className="!tw-h-12 p-0 !tw-gap-0 data-[active=true]:tw-bg-gradient-primary hover:tw-bg-white/5 hover:tw-text-white !tw-rounded-xl"
          >
            <div className="!tw-h-12 tw-w-12 tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center">
              <Fingerprint className="tw-size-5" />
            </div>
            <span>{orgName ?? 'Super Admin'}</span>
            <div className="tw-flex-grow" />
            <ChevronRight className="tw-h-4 tw-w-4 tw-mr-3 tw-text-white tw-opacity-50 group-data-[collapsible=icon]:tw-hidden" />
          </Link>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
