'use client';

import { LogOut } from 'lucide-react';
import { useAuth } from 'src/app/modules/auth';

import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from 'src/app/components/ui/sidebar';

export function NavLogout() {
  const { logout } = useAuth();
  return (
    <SidebarMenu className="tw-gap-3">
      <SidebarMenuItem className="tw-h-12">
        <SidebarMenuButton
          onClick={logout}
          className="!tw-h-12 p-0 !tw-gap-0 data-[active=true]:tw-bg-gradient-primary hover:tw-bg-white/5 hover:tw-text-white !tw-rounded-xl"
        >
          <div className="tw-h-12 tw-w-12 tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center">
            <LogOut className="tw-size-5" />
          </div>
          <span>Logout</span>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
