import WorkHistoryForm from './work-history-form';
import { BriefcaseBusiness, PlusIcon, GraduationCap, Medal, PencilLine } from 'lucide-react';
import ProfileAccordionItem from 'src/app/components/common/profile-accordion-item';
import { Accordion } from 'src/app/components/ui/accordion';
import { useEditUserContext } from './context';
import EducationHistoryForm from './education-history-form';
import CertificationHistoryForm from './certification-history-form';
import { Button } from 'src/app/components/ui/button';
import {
  CertificationHistoryDeleteModal,
  EducationHistoryDeleteModal,
  WorkHistoryDeleteModal,
} from './delete-modal';
import dayjs from 'dayjs';

const WorkHistoryAndEducation = () => {
  const {
    workHistory,
    educationHistory,
    certifications,
    workForm,
    defaultWorkHistory,
    handleWorkDelete,
    educationForm,
    defaultEducationHistory,
    handleEducationDelete,
    selected,
    setSelected,
    certificationForm,
    handleCertificationDelete,
    defaultCertificationHistory,
  } = useEditUserContext();

  return (
    <div className="tw-space-y-6">
      <div className="tw-grid tw-grid-cols-[2fr_1fr] tw-gap-4 lg:tw-gap-6 xl:tw-gap-8 ">
        <div className="">
          <h2 className="tw-text-base tw-mb-2 tw-text-gray-light2 tw-font-semibold tw-tracking-wide">
            Work History
          </h2>
          {workHistory.length === 0 ? (
            <div className="tw-flex tw-flex-1 tw-h-[calc(100%-2rem)] tw-border tw-border-dashed tw-border-primary/20 tw-rounded-xl tw-items-center tw-justify-center">
              <p className="tw-text-sm tw-text-gray-dark">You haven't added any work history yet</p>
            </div>
          ) : (
            <Accordion type="single" collapsible className="w-full tw-space-y-4">
              {workHistory.map((item) => (
                <ProfileAccordionItem
                  key={item.id}
                  value={item.id!}
                  title={item.title}
                  subtitle={item.companyName}
                  Icon={BriefcaseBusiness}
                  description={item.description}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  isCurrent={item.isCurrentlyWorking}
                  actions={
                    <div className="tw-flex tw-gap-2">
                      <WorkHistoryForm
                        type="update"
                        open={selected === item.id}
                        onOpenChange={(open) => {
                          if (!open) {
                            setSelected(null);
                          }
                        }}
                        onClick={() => {
                          workForm.reset(item);
                          setSelected(item.id!);
                        }}
                      >
                        <Button type="button" variant="ghost" size="icon">
                          <PencilLine className="tw-w-4 tw-h-4 tw-text-gray-light2" />
                        </Button>
                      </WorkHistoryForm>
                      <WorkHistoryDeleteModal onDelete={() => handleWorkDelete(item.id!)} />
                    </div>
                  }
                />
              ))}
            </Accordion>
          )}
        </div>
        <WorkHistoryForm
          onClick={() => {
            workForm.reset(defaultWorkHistory);
            setSelected('addWorkHistory');
          }}
          open={selected === 'addWorkHistory'}
          onOpenChange={(open) => {
            if (!open) {
              setSelected(null);
            }
          }}
          className="tw-w-full tw-mt-8"
        >
          <div className="tw-border tw-border-primary/20 tw-border-dashed tw-rounded-xl tw-p-4 tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-2 tw-w-full">
            <div className="tw-inline-flex tw-items-center tw-justify-center tw-p-2 tw-rounded-full tw-border-2 tw-border-primary/5">
              <PlusIcon className="tw-w-4 tw-h-4" />
            </div>
            <p className="tw-text-sm tw-text-primary-dark tw-font-bold">Work History</p>
            <p className="tw-text-xs tw-text-gray-dark tw-text-center">
              By showcasing your work history, you're painting a vivid picture of your skills,
              experiences, and growth over time.
            </p>
          </div>
        </WorkHistoryForm>
      </div>
      <div>
        <div className="tw-grid tw-grid-cols-[2fr_1fr] tw-gap-4 lg:tw-gap-6 xl:tw-gap-8 ">
          <div className="">
            <h2 className="tw-text-base tw-mb-2 tw-text-gray-light2 tw-font-semibold tw-tracking-wide">
              Educational Background
            </h2>
            {educationHistory.length === 0 ? (
              <div className="tw-flex tw-flex-1 tw-h-[calc(100%-2rem)] tw-border tw-border-dashed tw-border-primary/20 tw-rounded-xl tw-items-center tw-justify-center">
                <p className="tw-text-sm tw-text-gray-dark">
                  You haven't added any educational background yet
                </p>
              </div>
            ) : (
              <Accordion type="single" collapsible className="w-full tw-space-y-4">
                {educationHistory.map((item) => (
                  <ProfileAccordionItem
                    key={item.id}
                    title={item.institution}
                    subtitle={item.degree}
                    Icon={GraduationCap}
                    value={item.id!}
                    description={item.description}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    isCurrent={item.isCurrentlyStudying}
                    actions={
                      <div className="tw-flex tw-gap-2">
                        <EducationHistoryForm
                          type="update"
                          open={selected === item.id}
                          onOpenChange={(open) => {
                            if (!open) {
                              setSelected(null);
                            }
                          }}
                          onClick={() => {
                            educationForm.reset(item);
                            setSelected(item.id!);
                          }}
                        >
                          <Button type="button" variant="ghost" size="icon">
                            <PencilLine className="tw-w-4 tw-h-4 tw-text-gray-light2" />
                          </Button>
                        </EducationHistoryForm>
                        <EducationHistoryDeleteModal
                          onDelete={() => handleEducationDelete(item.id!)}
                        />
                      </div>
                    }
                  />
                ))}
              </Accordion>
            )}
          </div>
          <EducationHistoryForm
            onClick={() => {
              educationForm.reset(defaultEducationHistory);
              setSelected('addEducationHistory');
            }}
            open={selected === 'addEducationHistory'}
            onOpenChange={(open) => {
              if (!open) {
                setSelected(null);
              }
            }}
            className="tw-w-full tw-mt-8"
          >
            <div className="tw-border tw-border-primary/20 tw-border-dashed tw-rounded-xl tw-p-4 tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-2 tw-w-full">
              <div className="tw-inline-flex tw-items-center tw-justify-center tw-p-2 tw-rounded-full tw-border-2 tw-border-primary/5">
                <PlusIcon className="tw-w-4 tw-h-4" />
              </div>
              <p className="tw-text-sm tw-text-primary-dark tw-font-bold">Education</p>
              <p className="tw-text-xs tw-text-gray-dark tw-text-center">
                By showcasing your educational background, you highlight your knowledge and open
                doors to tailored opportunities.
              </p>
            </div>
          </EducationHistoryForm>
        </div>
      </div>
      <div>
        <div className="tw-grid tw-grid-cols-[2fr_1fr] tw-gap-4 lg:tw-gap-6 xl:tw-gap-8 ">
          <div className="">
            <h2 className="tw-text-base tw-mb-2 tw-text-gray-light2 tw-font-semibold tw-tracking-wide">
              Certificate History
            </h2>
            {certifications.length === 0 ? (
              <div className="tw-flex tw-flex-1 tw-h-[calc(100%-2rem)] tw-border tw-border-dashed tw-border-primary/20 tw-rounded-xl tw-items-center tw-justify-center">
                <p className="tw-text-sm tw-text-gray-dark">
                  You haven't added any certifications yet
                </p>
              </div>
            ) : (
              <div className="tw-space-y-4">
                {certifications.map((item) => (
                  <div className="tw-border tw-border-stroke-light tw-rounded-xl data-[state=open]:tw-border-primary data-[state=open]:tw-bg-gray-light hover:tw-bg-gray-light/50">
                    <div className="tw-py-4 tw-px-6 hover:!tw-no-underline">
                      <div className="tw-flex tw-flex-1 tw-items-center tw-justify-between tw-gap-4">
                        <div className="tw-flex tw-flex-1 tw-items-center tw-gap-4">
                          <div className="tw-size-9 tw-flex tw-items-center tw-justify-center ">
                            <Medal className="tw-w-8 tw-h-8 tw-text-primary-dark" />
                          </div>
                          <div className="tw-flex tw-flex-col tw-gap-0">
                            <h3 className="tw-text-base tw-text-primary-dark tw-font-bold tw-tracking-wide">
                              {item.name}
                            </h3>
                            <p className="tw-text-sm tw-text-primary-black -tw-mt-1">
                              {item.certificateId}
                            </p>
                            {item.dateAcquired?.year && item.dateAcquired?.month && (
                              <p className="tw-text-sm tw-text-primary-black">
                                {dayjs(
                                  `${item.dateAcquired?.year}-${item.dateAcquired?.month}`
                                ).format('MMM YYYY')}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="tw-pr-4">
                          <div className="tw-flex tw-gap-2">
                            <CertificationHistoryForm
                              type="update"
                              open={selected === item.id}
                              onOpenChange={(open) => {
                                if (!open) {
                                  setSelected(null);
                                }
                              }}
                              onClick={() => {
                                certificationForm.reset(item);
                                setSelected(item.id!);
                              }}
                            >
                              <Button type="button" variant="ghost" size="icon">
                                <PencilLine className="tw-w-4 tw-h-4 tw-text-gray-light2" />
                              </Button>
                            </CertificationHistoryForm>
                            <CertificationHistoryDeleteModal
                              onDelete={() => handleCertificationDelete(item.id!)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <CertificationHistoryForm
            open={selected === 'addCertificationHistory'}
            onOpenChange={(open) => {
              if (!open) {
                setSelected(null);
              }
            }}
            onClick={() => {
              certificationForm.reset(defaultCertificationHistory);
              setSelected('addCertificationHistory');
            }}
            className="tw-w-full tw-mt-8"
          >
            <div className="tw-border tw-border-primary/20 tw-border-dashed tw-rounded-xl tw-p-4 tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-2 tw-w-full hover:tw-bg-gray-light/50">
              <div className="tw-inline-flex tw-items-center tw-justify-center tw-p-2 tw-rounded-full tw-border-2 tw-border-primary/5">
                <PlusIcon className="tw-w-4 tw-h-4" />
              </div>
              <p className="tw-text-sm tw-text-primary-dark tw-font-bold">Certificate</p>
              <p className="tw-text-xs tw-text-gray-dark tw-text-center">
                By showcasing your certificates, you're painting a vivid picture of your skills,
                experiences, and growth over time.
              </p>
            </div>
          </CertificationHistoryForm>
        </div>
      </div>
    </div>
  );
};

export default WorkHistoryAndEducation;
