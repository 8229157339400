import { LucideIcon } from 'lucide-react';
import { FC, ReactNode } from 'react';
import { AccordionContent, AccordionTrigger } from '../ui/accordion';
import { AccordionItem } from '../ui/accordion';
import dayjs from 'dayjs';

interface ProfileAccordionItemProps {
  value: string;
  title: string; // e.g., "Senior Software Developer", "Bachelor's Degree"
  subtitle: string; // e.g., "Point Network", "Harvard University"
  startDate: {
    year: number;
    month: number;
  };
  endDate?: {
    year: number;
    month: number;
  } | null;
  Icon: LucideIcon; // Custom icon (e.g., briefcase for work, school for education)
  description?: string; // Additional details or summary
  isExpanded?: boolean; // Controls default state
  actions?: ReactNode; // Custom actions (edit, delete, etc.)
  isCurrent?: boolean;
}

const ProfileAccordionItem: FC<ProfileAccordionItemProps> = ({
  title,
  subtitle,
  Icon,
  value,
  description,
  startDate,
  endDate,
  actions,
  isCurrent,
}) => {
  const formatDateRange = () => {
    if (!startDate) return '';

    const start = dayjs(`${startDate.year}-${startDate.month}`);
    const end = endDate ? dayjs(`${endDate.year}-${endDate.month}`) : dayjs();

    const years = end.diff(start, 'year');
    const months = end.diff(start, 'month') % 12;

    let duration = '';
    if (years > 0) {
      duration += `${years} year${years > 1 ? 's' : ''}`;
      if (months > 0) duration += ` and ${months} month${months > 1 ? 's' : ''}`;
    } else if (months > 0) {
      duration += `${months} month${months > 1 ? 's' : ''}`;
    }

    const startFormatted = start.format('MMM YYYY');
    const endFormatted = endDate ? end.format('MMM YYYY') : '';

    return (
      <>
        {startFormatted} - {isCurrent ? 'Present' : endFormatted || ''}{' '}
        <span className="tw-text-gray-dark">({duration})</span>
      </>
    );
  };

  return (
    <AccordionItem
      value={value}
      className="tw-border tw-border-stroke-light tw-rounded-xl data-[state=open]:tw-border-primary data-[state=open]:tw-bg-gray-light hover:tw-bg-gray-light/50"
    >
      <AccordionTrigger className="tw-py-4 tw-px-6 hover:!tw-no-underline">
        <div className="tw-flex tw-flex-1 tw-items-center tw-justify-between tw-gap-4">
          <div className="tw-flex tw-flex-1 tw-items-center tw-gap-4">
            <div className="tw-size-9 tw-flex tw-items-center tw-justify-center ">
              <Icon className="tw-w-8 tw-h-8 tw-text-primary-dark" />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-0">
              <h3 className="tw-text-base tw-text-primary-dark tw-font-bold tw-tracking-wide">
                {title}
              </h3>
              <p className="tw-text-sm tw-text-primary-black -tw-mt-1">{subtitle}</p>
              <p className="tw-text-sm tw-text-primary-black">{formatDateRange()}</p>
            </div>
          </div>
          <div className="tw-pr-4">{actions}</div>
        </div>
      </AccordionTrigger>
      <AccordionContent className="tw-pl-[4.75rem] tw-text-sm tw-text-primary-black">
        {description?.split('\n').map((line, index) => (
          <p key={index} className="tw-mb-1 last:tw-mb-0">
            {line}
          </p>
        ))}
      </AccordionContent>
    </AccordionItem>
  );
};

export default ProfileAccordionItem;
