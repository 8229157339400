import { Config } from 'src/config';
import { presignedAPI } from '.';

interface PresignedUrlRequest {
  fileName: string;
  fileType: string;
}

interface PresignedUrlResponse {
  url: string;
  fullLocation: string;
  fields?: {
    [key: string]: string;
  };
}

export const generatePresignedUrl = async (
  payload: PresignedUrlRequest
): Promise<PresignedUrlResponse> => {
  try {
    const response = await presignedAPI.post<PresignedUrlResponse>('', {
      fileName: payload.fileName,
      fileType: payload.fileType,
    });

    if (!response?.data?.url) {
      throw new Error('Failed to generate presigned URL');
    }

    return {
      ...response.data,
      fullLocation: `https://${Config.services.aws.bucket}.s3.amazonaws.com/${payload.fileName}`,
    };
  } catch (error) {
    console.error('Error generating presigned URL:', error);
    throw error;
  }
};

interface UploadFileToS3Props {
  presignedUrl: string;
  file: File;
}

interface S3SuccessResponse {
  success: true;
  data: {
    url: string;
    fullLocation: string;
  };
}

interface S3ErrorResponse {
  success: false;
  error: {
    message: string;
    status: number;
  };
}

type S3Response = S3SuccessResponse | S3ErrorResponse;

export const uploadFileToS3 = async ({
  presignedUrl,
  file,
}: UploadFileToS3Props): Promise<S3Response> => {
  try {
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (!response.ok) {
      return {
        success: false,
        error: {
          message: 'Failed to upload file to S3',
          status: response.status,
        },
      };
    }

    return {
      success: true,
      data: {
        url: presignedUrl,
        fullLocation: '',
      },
    };
  } catch (error) {
    console.error('Error uploading file to S3:', error);
    return {
      success: false,
      error: {
        message: error instanceof Error ? error.message : 'Unknown error occurred during upload',
        status: 500,
      },
    };
  }
};

export const uploadToS3WithPresignedUrl = async (
  file: File,
  fileName: string
): Promise<S3Response> => {
  try {
    // Get presigned URL
    const presignedData = await generatePresignedUrl({
      fileName,
      fileType: file.type,
    });

    if (!presignedData.url) {
      return {
        success: false,
        error: {
          message: 'Failed to generate presigned URL',
          status: 400,
        },
      };
    }

    // Upload file using presigned URL
    const uploadResponse = await uploadFileToS3({
      presignedUrl: presignedData.url,
      file,
    });

    if (!uploadResponse.success) {
      return uploadResponse;
    }

    const fullLocation = `https://${Config.services.aws.bucket}.s3.amazonaws.com/${fileName}`;

    return {
      success: true,
      data: {
        url: presignedData.url,
        fullLocation,
      },
    };
  } catch (error) {
    console.error('Error in S3 upload process:', error);
    return {
      success: false,
      error: {
        message: error instanceof Error ? error.message : 'Unknown error occurred',
        status: 500,
      },
    };
  }
};
