import React, { useMemo } from 'react';
import { Input } from '../ui/input';
import { cn } from 'src/lib/utils';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';
import { X } from 'lucide-react';

interface MultiTagInputProps {
  tags: string[];
  onChange: (tags: string[]) => void;
  placeholder?: string;
  maxTags?: number;
  caseSensitive?: boolean;
}

interface FormattedTag {
  tag: string;
  normalized: string;
}

const normalizeTag = (tag: string) => {
  return tag.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

const MultiTagInput = ({
  tags = [],
  onChange,
  placeholder = 'Enter tags',
  maxTags,
  caseSensitive = true,
}: MultiTagInputProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const [existingTag, setExistingTag] = React.useState<FormattedTag | null>(null);
  const [isMaxTags, setIsMaxTags] = React.useState(false);
  const [newTag, setNewTag] = React.useState<FormattedTag | null>(null);

  const formattedTags = useMemo(
    () =>
      tags.map((tag) => ({
        tag: tag,
        normalized: normalizeTag(tag),
      })),
    [tags]
  );

  const addTag = (value: string) => {
    const newTag = value.trim();
    const normalizedNewTag = normalizeTag(newTag);

    const isTagExists = (tag: string) =>
      caseSensitive
        ? tags.includes(tag)
        : formattedTags.some((t) => t.normalized === normalizeTag(tag));

    const handleTagAddition = () => {
      if (maxTags && tags.length >= maxTags) {
        setInputValue('');
        setIsMaxTags(true);
        setTimeout(() => setIsMaxTags(false), 1000);
        return;
      }
      onChange([...tags, newTag]);
      setNewTag({ tag: newTag, normalized: normalizedNewTag });
      setTimeout(() => setNewTag(null), 1000);
      setInputValue('');
    };

    const handleExistingTag = () => {
      setInputValue(newTag);
      setExistingTag({ tag: newTag, normalized: normalizedNewTag });
      setTimeout(() => setExistingTag(null), 1000);
    };

    if (newTag && !isTagExists(newTag)) {
      handleTagAddition();
    } else if (isTagExists(newTag)) {
      handleExistingTag();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.endsWith(',')) {
      addTag(value.slice(0, -1));
    } else {
      setInputValue(value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addTag(inputValue);
    }
  };

  const handleRemoveTag = (tag: string) => {
    onChange(tags.filter((t) => t !== tag));
  };

  const RemoveButton = ({ tag }: { tag: string }) => {
    return (
      <Button
        type="button"
        variant="ghost"
        size="inner"
        rounded
        className="tw-p-0.5"
        onClick={() => handleRemoveTag(tag)}
      >
        <X className="tw-w-3 tw-h-3" />
      </Button>
    );
  };

  return (
    <div>
      <div className="tw-relative">
        <Input
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />
        {maxTags && tags.length > 0 && (
          <div className="tw-absolute tw-top-0 tw-right-3 tw-h-full tw-flex tw-items-center tw-justify-center">
            <Badge
              className={cn(
                'tw-text-xs tw-text-primary-dark tw-transition-all tw-duration-300',
                isMaxTags && '!tw-border-primary tw-animate-wiggle'
              )}
            >
              {tags.length}/{maxTags}
            </Badge>
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mt-3">
        {formattedTags.map(({ tag, normalized }) => (
          <Badge
            key={tag}
            className={cn(
              'tw-inline-flex tw-gap-0.5 tw-transition-all tw-duration-300 tw-pr-0.5 tw-pl-3',
              {
                '!tw-border-destructive/70 tw-animate-wiggle ': caseSensitive
                  ? existingTag?.tag === tag
                  : existingTag?.normalized === normalized,
                '!tw-border-primary  tw-animate-wiggle': caseSensitive
                  ? newTag?.tag === tag
                  : newTag?.normalized === normalized,
              }
            )}
          >
            <span
              className={cn({
                'tw-text-primary-dark': caseSensitive
                  ? existingTag?.tag === tag
                  : existingTag?.normalized === normalized,
              })}
            >
              {tag}
            </span>
            <RemoveButton tag={tag} />
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default MultiTagInput;
