import React, { FC, useEffect, useState } from 'react';
import { Config } from '../../../../../config';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as VetAI from '../../AiInterviews/VetAI';
import {
  DEFAULT_QUESTIONS,
  DEFAULT_QUESTIONS_APPLICANT,
  VetAIInterviewType,
} from '../../AiInterviews/constants';
import { GatherFeedback } from '../../AiInterviews/GatherFeedback';
import { RequestAnotherInterview } from '../../AiInterviews/RequestAnotherInterview';
import { InterviewScreen } from '../../AiInterviews/InterviewScreen';
import { PrepareInterview } from '../../AiInterviews/PrepareInterview';
import { InterviewPreviewPage } from '../../AiInterviews/InterviewPreviewPage';
import { useAuth } from '../../../auth';
const joyRideSteps = [
  {
    disableBeacon: true,
    target: '#question-type',
    content: (
      <div className="text-left">
        <h3 className="text-body15 font-bold">Question Type</h3>
        <p className="text-body12 font-medium">
          This field shows the subject of the upcoming question, giving you a preview of the topic
          you'll be addressing. Use this to guide your response and focus on the relevant area of
          expertise.
        </p>
      </div>
    ),
  },
  {
    target: '#timer',
    content: (
      <div className="text-left">
        <h3 className="text-body15 font-bold">Timer</h3>
        <p className="text-body12 font-medium">
          You’ll have 3 minutes to answer each question. The timer is here to help you manage your
          time and stay on track. Once the 3 minutes are up, you’ll automatically move to the next
          question.
        </p>
      </div>
    ),
  },
  {
    target: '#progress',
    content: (
      <div className="text-left">
        <h3 className="text-body15 font-bold">Progress</h3>
        <p className="text-body12 font-medium">
          The progress bar shows how far along you are in the interview. It helps you track your
          completion and see how many questions are left. Keep an eye on it to stay on pace!
        </p>
      </div>
    ),
  },
  {
    target: '#camera',
    content: (
      <div className="text-left">
        <h3 className="text-body15 font-bold">Camera Preview</h3>
        <p className="text-body12 font-medium">
          This is where you’ll see a live view of your camera feed. Make sure your video looks clear
          before starting your recording!
        </p>
      </div>
    ),
  },
  {
    target: '#ai-interviewer',
    content: (
      <div className="text-left">
        <h3 className="text-body15 font-bold">AI Interviewer</h3>
        <p className="text-body12 font-medium">
          This box will display everything the AI interviewer says, so you can easily follow along
          with the questions and instructions during your interview.
        </p>
      </div>
    ),
  },
  {
    target: '#ai-interviewer-camera',
    content: (
      <div className="text-left">
        <h3 className="text-body15 font-bold">AI Interviewer</h3>
        <p className="text-body12 font-medium">
          This virtual assistant will guide you through the interview and ask the questions during
          your recording!
        </p>
      </div>
    ),
  },
  {
    target: '#cancel-button',
    content: (
      <div className="text-left">
        <h3 className="text-body15 font-bold">Cancel Interview Button</h3>
        <p className="text-body12 font-medium">
          Clicking 'Cancel Interview' will cancel your video and discard any progress made.
        </p>
      </div>
    ),
  },
  {
    target: '#next-button',
    content: (
      <div className="text-left">
        <h3 className="text-body15 font-bold">Next Button</h3>
        <p className="text-body12 font-medium">
          After answering a question, click 'Next' to proceed to the following one.
        </p>
      </div>
    ),
  },
];

export interface VetAIProps {
  isApplicant: boolean;
  userId: string;
  isProfileComplete: { isComplete: boolean; missingFields: string[] };
}

type ModalView =
  | 'profileCompletness'
  | 'areYouReady'
  | 'interview'
  | 'practice'
  | 'updateProfile'
  | 'completed'
  | 'requestAnother'
  | 'feedback'
  | 'preview';
export const VetAIInterview: FC<VetAIProps> = (props: VetAIProps) => {
  const { currentUser } = useAuth();
  const [started, setStarted] = useState<boolean>(false);
  const [view, setView] = useState<ModalView>('profileCompletness');
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (started) {
        const confirmationMessage =
          'You will need to request another interview if you leave now. Are you sure you want to leave?';
        e.preventDefault();
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [started]);

  // const fetchInterviews = async () => {
  //   const response = await axios.get(
  //     `${Config.api_url}/candidate/ai-interview/list?interview_type=vet_ai&candidate_id=${props?.userId}&statuses[]=pending&statuses[]=completed&statuses[]=scoring&include_questions=0`
  //   );
  //   return response.data.data;
  // };

  // const { isLoading, data } = useQuery<VetAIInterviewType[]>(
  //   ['candidate.ai-interview', props.userId],
  //   fetchInterviews
  // );

  const handleClose = () => {
    setView('profileCompletness');
    setShow(false);
    document.getElementById('stop-recording-btn')?.click();
  };

  // if (isLoading) {
  //   return 'Loading...';
  // }

  const isInterviewCompleted = currentUser?.profile_status?.completion_status?.vet_ai;

  return (
    <>
      <Modal
        centered
        backdrop="static"
        keyboard={false}
        className={'fade !p-0'}
        animation={true}
        show={show && view !== 'practice' && view !== 'interview' && view !== 'preview'}
        fullscreen={view === 'updateProfile' ? true : undefined}
      >
        <Modal.Body className="!p-0">
          <span className="top-border block w-full h-[10px] rounded-t-[6px]" />

          {view === 'profileCompletness' && (
            <div className="modal-body">
              {!props.isProfileComplete.isComplete ? (
                <VetAI.MissingProfile
                  handleClose={handleClose}
                  missingFields={props.isProfileComplete.missingFields}
                />
              ) : (
                <PrepareInterview
                  showPractice
                  startText="Skip Practice"
                  paragraphs={[
                    'She’s here to guide you through your video introduction with a mix of behavioral and technical questions.',
                    "You'll have 2 attempts to complete the interview, so no need to stress!",
                    "Let's start with a quick practice round to get you comfortable!",
                  ]}
                  handleClose={handleClose}
                  handleStart={() => {
                    setView('areYouReady');
                  }}
                  handleStartPractice={() => {
                    setView('practice');
                  }}
                />
              )}
            </div>
          )}

          {view === 'areYouReady' && (
            <VetAI.AreYouReady
              handleClose={handleClose}
              handleStart={() => {
                setView('preview');
              }}
            />
          )}

          {view === 'requestAnother' && (
            <RequestAnotherInterview handleClose={() => setShow(false)} interviewType="VetAI" />
          )}

          {view === 'updateProfile' && (
            <VetAI.InterviewFinish handleFinish={() => setView('completed')} />
          )}

          {view === 'completed' && (
            <VetAI.InterviewCompleted handleStartFeedback={() => setView('feedback')} />
          )}

          {view === 'feedback' && (
            <GatherFeedback handleClose={() => setShow(false)} interviewType="VetAI" />
          )}
        </Modal.Body>
      </Modal>

      {(view === 'practice' || view === 'interview') && (
        // the z index needs to the match the overlay z index of the joyride comp
        <div className="fixed top-0 left-0 w-screen h-screen bg-white z-[199]">
          <InterviewScreen
            candidateId={props.userId}
            interviewType="vet_ai"
            isPractice={view === 'practice'}
            handleCancel={handleClose}
            handleFinish={() => {
              setView(view === 'practice' ? 'areYouReady' : 'updateProfile');
            }}
            joyrideSteps={joyRideSteps}
            defaultQuestions={props.isApplicant ? DEFAULT_QUESTIONS_APPLICANT : DEFAULT_QUESTIONS}
          />
        </div>
      )}

      {view === 'preview' ? (
        <InterviewPreviewPage
          handleClose={handleClose}
          handleStart={() => {
            setView('interview');
            setStarted(true);
          }}
        />
      ) : null}

      {/* Only allow to do an interview if it has not been recorded */}
      {!isInterviewCompleted ? (
        <div className="mt-8 relative cursor-pointer" onClick={() => setShow(true)}>
          <img
            src="/media/pages/interview_banner.png"
            alt="banner"
            className="!w-[590px] !h-[168px] object-cover"
          />
          <div
            className={
              'absolute rounded-[16px] top-0 !w-full !h-[168px] bg-gradient-to-r from-[#732FA9A3] to-[#BF09FF]'
            }
          >
            <div
              className={
                'flex flex-col justify-center items-center h-[168px] max-w-[772px] text-center mx-auto'
              }
            >
              <img
                alt="interview icon"
                src="/media/icons/interview_icon.png"
                className="!w-[56px] !h-[56px] mb-2"
              />
              <h2 className={'text-white text-body15 font-bold mb-2'}>
                Record Your AI Live Introduction Interview
              </h2>
              <h5 className="text-white text-body12 font-medium">
                To get matched with the best opportunities, please record your introduction
                interview. This helps us understand your background and skills, ensuring we find the
                perfect fit for you.
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-16 text-center">
          <h3 className="text-h3 font-bold">
            You have already completed your <span className="text-pri">VetAI</span> interview!
          </h3>

          <Link
            to={`/candidate/interviews`}
            className="btn btn-primary btn-sm main-gradient main-button block my-4"
          >
            Go to Interviews
          </Link>

          <p>
            If you want to record your VetAI interview again, you can{' '}
            <span
              className="text-pri cursor-pointer"
              onClick={() => {
                setView('requestAnother');
                setShow(true);
              }}
            >
              request another interview.
            </span>
          </p>
        </div>
      )}
    </>
  );
};
