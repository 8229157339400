'use client';

import * as React from 'react';

import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from 'src/app/components/ui/sidebar';
import { toAbsoluteUrl } from 'src/_theme/helpers/AssetHelpers';
import { Link } from 'react-router-dom';
import { useAuth, UserTypeEnum } from 'src/app/modules/auth';

export function Logo() {
  const { accountType } = useAuth();
  const isCandidate = accountType === UserTypeEnum.CANDIDATE;

  const link = isCandidate ? '/candidate/welcome' : '/customer/dashboard';
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <div className="tw-h-[40px] tw-my-4">
          <SidebarMenuButton size="lg" className="tw-relative" asChild>
            <Link to={link}>
              <div className="tw-relative tw-h-[48px] tw-inline-flex tw-items-center">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl(`/media/logos/logo.svg`)}
                  className="tw-h-[20px] tw-transition-opacity tw-duration-300 tw-opacity-100 group-data-[collapsible=icon]:tw-opacity-0"
                />
                <div className="tw-absolute tw-right-0 tw-left-0 tw-flex tw-items-center tw-justify-center tw-top-0 tw-h-[48px] tw-transition-opacity tw-duration-300 tw-opacity-0 group-data-[collapsible=icon]:tw-opacity-100">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl(`/media/logos/arrow_purple.svg`)}
                    className="tw-h-[36px] "
                  />
                </div>
              </div>
            </Link>
          </SidebarMenuButton>
        </div>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
