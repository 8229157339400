/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../app/modules/auth';
import CSS from 'csstype';
import { Link } from 'react-router-dom';

const inlineStyle: CSS.Properties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  width: '100%',
  margin: 0,
  height: '16px',
  backgroundColor: '#ccc',
  textAlign: 'center',
  fontSize: '9px',
  borderBottom: '1px dashed #ddd',
  zIndex: '9999',
  overflow: 'hidden',
};

export function AdminHeader() {
  const { currentUser } = useAuth();
  const [orgName, setOrgName] = useState('');

  useEffect(() => {
    const saved = localStorage.getItem('impersonated_org');
    let org_name = null;
    if (saved) {
      org_name = JSON.parse(saved);
    }

    setOrgName(org_name);
  }, []);

  if (!currentUser || !currentUser?.superadmin) {
    return <></>;
  }

  return (
    <div id="distro_app_header_admin_container" style={inlineStyle}>
      <Link to={'/superadmin'} title={'Superadmin'}>
        <i className="bi bi-person-fill-gear !tw-text-[12px]" />
      </Link>
      {orgName && (
        <>
          <span className={'ms-1 me-1'}>::</span> {orgName}
        </>
      )}
    </div>
  );
}
