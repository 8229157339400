import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'src/app/components/ui/button';
import UserCard from 'src/app/components/UserCard';
import { useATS } from './context/ATSContext';
import { cn } from 'src/lib/utils';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/app/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/app/components/ui/select';
import { SalaryRanges } from 'src/app/util/salary';
import { startAvailabityOptions } from 'src/app/util/startAvailabilityOptions';
import { availabilityOptions } from './context/types';
import LoadingScreen from './loading-screen';
import { CircleCheck, ClockAlert, LaptopMinimalCheck, TriangleAlert } from 'lucide-react';
const showStatusIcon = (status: boolean) =>
  status ? (
    <CircleCheck className="tw-text-green-500 tw-size-4" />
  ) : (
    <TriangleAlert className="tw-text-orange-500 tw-size-4" />
  );

const PreviewUser = () => {
  const {
    token,
    candidate,
    showAvatar,
    setShowAvatar,
    isConfirmed,
    setIsConfirmed,
    form,
    onFormSubmit,
    isLoading,
    isChrome,
    isDone,
    isInterviewExpired,
    navigateToPreviewResume,
  } = useATS();

  const handleContinue = () => {
    setIsConfirmed(true);
  };

  const handleSkip = () => {
    navigateToPreviewResume();
  };

  useEffect(() => {
    setShowAvatar(false);

    return () => {
      setShowAvatar(true);
    };
  }, []);

  const renderContent = () => {
    if (isInterviewExpired || !candidate || !token) {
      return (
        <div className="tw-text-center tw-max-w-xl tw-mx-auto tw-p-4">
          <p className="tw-text-lg tw-text-primary tw-font-bold tw-mb-2 tw-inline-flex tw-items-center tw-gap-1">
            This interview link has expired or is no longer available.
            <ClockAlert className="tw-size-6 tw-inline-flex" />
          </p>
          <p className="tw-text-gray-600 tw-text-sm">
            If you believe this is an error or need further assistance, please check your email for
            updates or contact our support team.
          </p>
        </div>
      );
    }
    if (isDone) {
      return (
        <div className="tw-text-center tw-max-w-xl tw-mx-auto tw-p-4">
          <p className="tw-text-lg tw-text-primary tw-font-bold tw-mb-2 tw-inline-flex tw-items-center tw-gap-1">
            You've already completed your interview! <LaptopMinimalCheck className="tw-size-6" />
          </p>
          <p className="tw-text-gray-600 tw-text-sm">
            There's nothing else you need to do at this time. We'll review your responses and reach
            out with the next steps. Check your email for updates!
          </p>
        </div>
      );
    }

    return (
      <>
        <p className="tw-text-sm tw-text-center tw-mb-0 tw-my-4 tw-flex tw-items-center tw-gap-1">
          For the best experience, we recommend using
          <span className="tw-font-bold">Google Chrome</span> as your browser
          {showStatusIcon(isChrome)}
        </p>
        <div
          className={cn(
            'tw-flex tw-flex-col tw-items-center tw-justify-center tw-transition-all tw-duration-200',
            {
              'tw-h-0 tw-opacity-0 tw-overflow-hidden': isConfirmed,
              'tw-h-[76px] tw-opacity-100': !isConfirmed,
            }
          )}
        >
          <Button variant="default" className="tw-mt-4 tw-w-[220px]" onClick={handleContinue}>
            Confirm & Continue
          </Button>
          <p className="tw-text-gray-500 tw-text-xs tw-mt-2 tw-text-center">
            Please confirm this is you before proceeding to the interview
          </p>
        </div>
        <div
          className={cn(
            'tw-mt-4 tw-w-[320px] tw-overflow-hidden tw-transition-all tw-duration-300',
            {
              'tw-h-[344px] tw-opacity-100': isConfirmed,
              'tw-h-0 tw-opacity-0': !isConfirmed,
            }
          )}
        >
          <h3 className="tw-text-lg tw-font-semibold tw-mb-4 tw-text-center">Additional Details</h3>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onFormSubmit)} className="tw-space-y-5 tw-px-1">
              <FormField
                control={form.control}
                name="salary_range_in_cents"
                render={({ field }) => (
                  <FormItem className="tw-space-y-0">
                    <FormLabel>Monthly Salary Range</FormLabel>
                    <div className="tw-relative">
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select monthly salary range" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="tw-max-h-[200px]">
                          {SalaryRanges.map((salary) => (
                            <SelectItem key={salary.value} value={salary.value}>
                              {salary.label.replace('-', ' - ')}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage className="tw-absolute -tw-bottom-[1.25rem] tw-left-1" />
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="availability"
                render={({ field }) => (
                  <FormItem className="tw-space-y-0">
                    <FormLabel>Availability Status</FormLabel>
                    <div className="tw-relative">
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select availability status" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="tw-max-h-[200px]">
                          {availabilityOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage className="tw-absolute -tw-bottom-[1.25rem] tw-left-1" />
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="start_availability"
                render={({ field }) => (
                  <FormItem className="tw-space-y-0">
                    <FormLabel>Start Availability</FormLabel>
                    <div className="tw-relative">
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select start availability" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {startAvailabityOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage className="tw-absolute -tw-bottom-[1.1rem] tw-left-1" />
                    </div>
                  </FormItem>
                )}
              />

              <div className="tw-flex tw-gap-3 tw-mt-6">
                <Button type="submit" variant="default" className="tw-flex-1">
                  Save & Continue
                </Button>
                <Button type="button" variant="outline" onClick={handleSkip} className="tw-flex-1">
                  Skip & Continue
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </>
    );
  };
  return (
    <section id="preview-user" className="relative tw-h-[calc(100vh-3.5rem)]">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
        {candidate && token && (
          <UserCard
            id={candidate.id}
            firstName={candidate.firstName}
            lastName={candidate.lastName}
            email={candidate.email}
            headline={candidate.jobTitle}
            avatarUrl={candidate.avatar}
            className={cn('tw-transition-all tw-duration-300', {
              'tw-opacity-0': showAvatar,
            })}
          />
        )}
        {renderContent()}
      </div>
      {isLoading && <LoadingScreen />}
    </section>
  );
};

export default PreviewUser;
