'use client';

import * as React from 'react';
import { NavMenu, NavMenuItem } from './nav-menu';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from 'src/app/components/ui/sidebar';
import { Logo } from './logo';
import { NavLogout } from './nav-logout';
import { NavUser } from './nav-user';
import { NavSuperAdmin } from './nav-super-admin';

import {
  UserRound,
  CalendarClock,
  BriefcaseBusiness,
  Calendar1,
  Receipt,
  UserRoundPlus,
  LayoutDashboard,
  Settings,
  Users,
} from 'lucide-react';
import { UserTypeEnum, useAuth } from 'src/app/modules/auth';

interface AppSidebarProps extends React.ComponentProps<typeof Sidebar> {
  accountType?: UserTypeEnum;
  isSuperAdmin?: boolean;
}

type Menus = {
  candidate: NavMenuItem[];
  customer: NavMenuItem[];
};

const menus: Menus = {
  candidate: [
    {
      name: 'Profile',
      url: '/candidate/profile',
      icon: UserRound,
    },
    {
      name: 'Interviews',
      url: '/candidate/interviews',
      icon: CalendarClock,
    },
    {
      name: 'Jobs',
      url: '/candidate/jobs',
      icon: BriefcaseBusiness,
    },
    {
      name: 'Calendar',
      url: '/candidate/calendar',
      icon: Calendar1,
    },
    {
      name: 'Payments',
      url: '/candidate/payment',
      icon: Receipt,
    },
    {
      name: 'Invitations',
      url: '/candidate/invite',
      icon: UserRoundPlus,
    },
  ],
  customer: [
    {
      name: 'Dashboard',
      url: '/customer/dashboard',
      icon: LayoutDashboard,
    },
    {
      name: 'Jobs',
      url: '/customer/jobs',
      icon: BriefcaseBusiness,
    },
    {
      name: 'Candidates',
      url: '/customer/candidates',
      icon: Users,
    },
    {
      name: 'Calendar',
      url: '/customer/calendar',
      icon: Calendar1,
    },
    {
      name: 'Hires',
      url: '/customer/hires',
      icon: UserRoundPlus,
    },
    {
      name: 'Payments',
      url: '/customer/payroll',
      icon: Receipt,
    },
    {
      name: 'Organization',
      url: '/customer/settings',
      icon: Settings,
    },
  ],
};

export function AppSidebar({ accountType, isSuperAdmin, ...props }: AppSidebarProps) {
  const { roles } = useAuth();
  const showUser = accountType === UserTypeEnum.CANDIDATE;

  // Filter menu items based on roles
  const menuItems =
    accountType === UserTypeEnum.CANDIDATE
      ? menus.candidate
      : menus.customer.filter((item) => {
          if (item.url === '/customer/dashboard' || item.url === '/customer/hires') {
            return (
              roles?.includes('owner') || roles?.includes('admin') || roles?.includes('manager')
            );
          }
          if (item.url === '/customer/payroll') {
            return (
              roles?.includes('owner') || roles?.includes('admin') || roles?.includes('billing')
            );
          }
          if (item.url === '/customer/settings') {
            return roles?.includes('owner') || roles?.includes('admin');
          }
          return (
            roles?.includes('owner') ||
            roles?.includes('admin') ||
            roles?.includes('manager') ||
            roles?.includes('peer')
          );
        });

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <Logo />
      </SidebarHeader>
      <SidebarContent>
        {showUser && <NavUser />}
        {isSuperAdmin && <NavSuperAdmin />}
        <NavMenu menus={menuItems} />
      </SidebarContent>
      <SidebarFooter>
        <NavLogout />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
