'use client';

import { type LucideIcon } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { checkIsActive } from 'src/_theme/helpers';
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from 'src/app/components/ui/sidebar';

export type NavMenuItem = {
  name: string;
  url: string;
  icon: LucideIcon;
  hidden?: boolean;
};

interface NavMenuProps {
  menus: NavMenuItem[];
}
export function NavMenu({ menus }: NavMenuProps) {
  const { pathname } = useLocation();
  return (
    <SidebarGroup className="group-data-[collapsible=icon]:hidden ">
      <SidebarMenu className="tw-gap-3">
        {menus.map((item) =>
          item.hidden ? null : (
            <SidebarMenuItem key={item.name} className="tw-h-12">
              <SidebarMenuButton asChild>
                <Link
                  to={item.url}
                  data-active={checkIsActive(pathname, item.url)}
                  className="!tw-h-12 p-0 !tw-gap-0 data-[active=true]:tw-bg-gradient-primary hover:tw-bg-white/5 hover:tw-text-white !tw-rounded-xl"
                >
                  <div className="!tw-h-12 tw-w-12 tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center">
                    <item.icon className="tw-size-5" />
                  </div>
                  <span>{item.name}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )
        )}
      </SidebarMenu>
    </SidebarGroup>
  );
}
