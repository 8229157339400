import React from 'react';
import { VetAIInterview } from '../components/VetAI';
import { useAuth } from '../../../auth';
import { useEditUserContext } from './context';

const Interview = () => {
  const { isApplicant } = useAuth();
  const { stepState, missingFields, profile } = useEditUserContext();

  return profile ? (
    <VetAIInterview
      isApplicant={isApplicant}
      userId={profile.user_id}
      isProfileComplete={{
        isComplete: stepState[0].isCompleted && stepState[1].isCompleted,
        missingFields: missingFields,
      }}
    />
  ) : null;
};

export default Interview;
