import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from 'src/app/components/ui/sidebar';
import { useAuth } from 'src/app/modules/auth';
import { checkIsActive } from 'src/_theme/helpers';
import { UserAvatar } from '../../common/user-avatar';

export function NavUser() {
  const { profile } = useAuth();
  const { pathname } = useLocation();
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <SidebarMenuButton size="lg" asChild className="">
          <Link
            to="/candidate/welcome"
            className="tw-bg-primary-black !tw-h-16 tw-pl-2.5 hover:tw-bg-white/5 tw-rounded-none data-[active=true]:tw-bg-gradient-primary  group-data-[collapsible=icon]:!tw-pl-2.5"
            data-active={checkIsActive(pathname, '/candidate/welcome')}
          >
            <UserAvatar
              src={profile?.avatar_url}
              alt={`${profile?.first_name || ''} ${profile?.last_name || ''}`}
              initials={`${profile?.first_name?.charAt(0) || ''}${
                profile?.last_name?.charAt(0) || ''
              }`}
              size={44}
            />
            <div className="tw-flex tw-flex-col tw-gap-1 tw-flex-grow group-data-[collapsible=icon]:tw-hidden">
              <p className="tw-text-white tw-text-sm tw-font-bold">
                {`${profile?.first_name || ''} ${profile?.last_name || ''}`}
              </p>
            </div>
            <ChevronRight className="tw-h-4 tw-w-4 tw-text-white tw-opacity-50 group-data-[collapsible=icon]:tw-hidden" />
          </Link>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
