import { Avatar as UIAvatar, AvatarImage, AvatarFallback } from 'src/app/components/ui/avatar';
import { cn } from 'src/lib/utils';

interface AvatarProps {
  src?: string;
  alt?: string;
  initials?: string;
  size?: number;
  className?: string;
  fallbackClassName?: string;
}

export function UserAvatar({
  src,
  alt,
  initials,
  size = 44, // Default size matching the nav-user avatar
  className,
  fallbackClassName,
}: AvatarProps) {
  return (
    <div className="tw-bg-white tw-rounded-full tw-border tw-border-primary">
      <UIAvatar
        className={cn('tw-border-white tw-bg-white', className)}
        style={{
          borderWidth: `${Math.floor(size / 11)}px`,
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <AvatarImage src={src} alt={alt || initials || 'Avatar'} />
        <AvatarFallback
          className={cn(
            'tw-rounded-full tw-font-bold tw-text-primary-black tw-bg-gray-light2',
            fallbackClassName
          )}
          style={{
            fontSize: `${size / 2.5}px`,
          }}
        >
          {initials ?? alt?.charAt(0)}
        </AvatarFallback>
      </UIAvatar>
    </div>
  );
}
