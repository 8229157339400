import axios from 'axios';
import { baseAPI } from '../axios';
import { setupAxios } from 'src/app/modules/auth';

// Organization-specific axios instance
export const candidateAPI = axios.create({
  baseURL: `${baseAPI.defaults.baseURL}/candidate`,
});

setupAxios(candidateAPI);
