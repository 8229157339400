import { Button } from 'src/app/components/ui/button';
import {
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  Form,
  FormControl,
  FormMessage,
} from 'src/app/components/ui/form';
import { Input } from 'src/app/components/ui/input';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'src/app/components/ui/sheet';
import dayjs from 'dayjs';
import { Checkbox } from 'src/app/components/ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/app/components/ui/select';
import { Textarea } from 'src/app/components/ui/textarea';
import { cn } from 'src/lib/utils';
import { useEditUserContext } from './context';
import { FC } from 'react';
import { JobFormData } from './work.schema';

const months = Array.from({ length: 12 }, (_, index) => ({
  value: `${index + 1}`,
  label: dayjs().month(index).format('MMMM'),
}));

const years = Array.from({ length: 40 }, (_, index) => ({
  value: `${dayjs().subtract(index, 'year').year()}`,
  label: dayjs().subtract(index, 'year').year(),
}));

interface WorkHistoryFormProps {
  className?: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'add' | 'update';
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const WorkHistoryForm: FC<WorkHistoryFormProps> = ({
  children,
  className,
  type = 'add',
  onClick,
  open,
  onOpenChange,
}) => {
  const { workForm: form, handleWorkSubmit } = useEditUserContext();

  const isCurrentlyWorking = form.watch('isCurrentlyWorking');
  const startDate = form.watch('startDate');
  const endDate = form.watch('endDate');

  const isDateValid = (type: 'start' | 'end', month: number, year: number) => {
    if (type === 'start' && endDate) {
      return !(year > endDate.year || (year === endDate.year && month > endDate.month));
    }
    if (type === 'end' && startDate) {
      return !(year < startDate.year || (year === startDate.year && month < startDate.month));
    }
    return true;
  };

  const addWorkHistory = async (data: JobFormData) => {
    await handleWorkSubmit(data, type);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetTrigger onClick={onClick} className={className} asChild>
          {children}
        </SheetTrigger>
        <SheetContent className="!tw-w-1/3 !tw-max-w-[500px]">
          <SheetHeader>
            <SheetTitle>Add Work History</SheetTitle>
            <SheetDescription>Tell us about your past job</SheetDescription>
          </SheetHeader>

          <Form {...form}>
            <form
              onSubmit={(e) => {
                form.handleSubmit(addWorkHistory)(e);
              }}
              className="tw-flex tw-flex-col tw-gap-4 tw-pt-4"
            >
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <div>
                      <FormLabel className="required">Title</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Software Engineer" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="companyName"
                render={({ field }) => (
                  <FormItem>
                    <div>
                      <FormLabel className="required">Company Name</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Google" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="isCurrentlyWorking"
                render={({ field }) => (
                  <FormItem>
                    <div className="tw-flex tw-items-center tw-gap-1">
                      <FormControl>
                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      </FormControl>
                      <FormLabel className="">I am currently working here</FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem>
                    <div>
                      <FormLabel className="required">Start Date</FormLabel>
                      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                        <div className="tw-flex tw-flex-col">
                          <FormDescription>Month</FormDescription>
                          <Select
                            onValueChange={(value) => {
                              field.onChange({ ...field.value, month: Number(value) });
                            }}
                            value={field.value.month.toString()}
                          >
                            <FormControl>
                              <SelectTrigger value={field.value.month.toString()}>
                                <SelectValue placeholder="Month" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {months.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                  disabled={
                                    !isDateValid('start', Number(option.value), field.value.year)
                                  }
                                >
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="tw-flex tw-flex-col">
                          <FormDescription>Year</FormDescription>
                          <Select
                            onValueChange={(value) => {
                              field.onChange({ ...field.value, year: Number(value) });
                            }}
                            value={field.value.year.toString()}
                          >
                            <FormControl>
                              <SelectTrigger value={field.value.year.toString()}>
                                <SelectValue placeholder="Year" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {years.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                  disabled={endDate && Number(option.value) > endDate.year}
                                >
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <FormMessage />
                      </div>
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endDate"
                render={({ field }) => (
                  <FormItem
                    className={cn(
                      'tw-transition-all tw-duration-300 tw-overflow-hidden tw-pb-0.5',
                      isCurrentlyWorking ? 'tw-h-0 -tw-mt-4' : 'tw-h-[82px] tw-mt-0'
                    )}
                  >
                    <div>
                      <FormLabel className="required">End Date</FormLabel>
                      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                        <div className="tw-flex tw-flex-col">
                          <FormDescription>Month</FormDescription>
                          <Select
                            onValueChange={(value) => {
                              field.onChange({ ...field.value, month: Number(value) });
                            }}
                            value={field.value?.month?.toString()}
                          >
                            <FormControl>
                              <SelectTrigger value={field.value?.month?.toString()}>
                                <SelectValue placeholder="Month" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {months.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                  disabled={
                                    !isDateValid(
                                      'end',
                                      Number(option.value),
                                      field.value?.year || 0
                                    )
                                  }
                                >
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="tw-flex tw-flex-col">
                          <FormDescription>Year</FormDescription>
                          <Select
                            onValueChange={(value) => {
                              field.onChange({ ...field.value, year: Number(value) });
                            }}
                            value={field.value?.year.toString()}
                          >
                            <FormControl>
                              <SelectTrigger value={field.value?.year.toString()}>
                                <SelectValue placeholder="Year" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {years.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                  disabled={startDate && Number(option.value) < startDate.year}
                                >
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                        <FormMessage />
                      </div>
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <div>
                      <FormLabel className="required">Description</FormLabel>
                      <FormDescription>
                        Please describe the skilled used at this role and work performed
                      </FormDescription>
                      <FormControl>
                        <Textarea {...field} rows={4} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <SheetFooter className="!tw-justify-start">
                {/* <SheetClose asChild>
                  <Button type="submit">Save changes</Button>
                </SheetClose> */}
                <Button type="submit">Save changes</Button>
              </SheetFooter>
            </form>
          </Form>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default WorkHistoryForm;
