import SimpleMDE from 'easymde';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { cn } from 'src/lib/utils';
import SimpleMdeReact from 'react-simplemde-editor';

interface RichTextEditorProps {
  value: string;
  defaultValue: string;
  onChange: (value: string) => void;
}

const wysiwygOptions: SimpleMDE.Options = {
  toolbar: ['bold', '|', 'italic', '|', 'unordered-list', '|', 'ordered-list', '|', 'preview'],
  autofocus: false,
  spellChecker: false,
  uploadImage: false,
  previewImagesInEditor: false,
  sideBySideFullscreen: false,
  status: false,
  previewRender: (plainText: string) => {
    plainText = plainText.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '');
    plainText = plainText.replace(
      /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/g,
      ''
    );
    return DOMPurify.sanitize(marked.parse(plainText), {
      ALLOWED_TAGS: ['b', 'em', 'strong', 'p', 'br', 'i', 'ul', 'li', 'ol'],
    });
  },
};

export default function RichTextEditor({ value, defaultValue, onChange }: RichTextEditorProps) {
  return (
    <div
      className={cn(
        '[&_div.editor-toolbar]:tw-bg-gray-light [&_div.editor-toolbar]:!tw-border-gray-light [&_div.editor-toolbar]:!tw-rounded-t-lg',
        '[&_div.editor-toolbar]:tw-py-1 [&_div.editor-toolbar]:tw-px-3',
        '[&_button.bold_i]:tw-text-gray-dark',
        '[&_button.italic_i]:tw-text-gray-dark',
        '[&_button.unordered-list_i]:tw-text-gray-dark',
        '[&_button.ordered-list_i]:tw-text-gray-dark',
        '[&_button.preview_i]:tw-text-gray-dark',
        '[&_div.CodeMirror]:tw-text-sm [&_div.CodeMirror]:tw-text-primary-dark',
        '[&_div.CodeMirror]:!tw-border-stroke-light [&_div.CodeMirror]:!tw-rounded-b-lg',
        '[&_div.CodeMirror]:tw-tracking-wide',
        '[&_div.CodeMirror-scroll]:!tw-min-h-[260px]',
        '[&_button.active]:!tw-bg-primary/20 [&_button.active]:!tw-text-primary-dark [&_button.active]:!tw-border-none'
      )}
    >
      <SimpleMdeReact
        value={value}
        defaultValue={defaultValue}
        options={wysiwygOptions}
        onChange={onChange}
      />
    </div>
  );
}
