import { z } from 'zod';
import { WorkHistory } from 'src/app/api/candidate/types';

const jobFormSchema = z
  .object({
    id: z.string().optional(),
    title: z.string().min(1, 'Title is required'),
    companyName: z.string().min(1, 'Company Name is required'),
    isCurrentlyWorking: z.boolean().optional(),
    startDate: z.object({
      month: z.number().min(1, 'Invalid month').max(12, 'Invalid month'),
      year: z
        .number()
        .min(1900, 'Invalid year')
        .max(new Date().getFullYear(), 'Start year cannot be in the future'),
    }),
    endDate: z
      .object({
        month: z.number().min(1, 'Invalid month').max(12, 'Invalid month'),
        year: z
          .number()
          .min(1900, 'Invalid year')
          .max(new Date().getFullYear(), 'End year cannot be in the future'),
      })
      .optional(),
    description: z.string().min(1, 'Description is required'),
  })
  .superRefine((data, ctx) => {
    if (data.isCurrentlyWorking) {
      return;
    }

    if (data.endDate) {
      // Validate that end date is after start date
      const startYear = data.startDate.year;
      const startMonth = new Date(`${data.startDate.month} 1, ${startYear}`).getMonth();
      const endYear = data.endDate.year;
      const endMonth = new Date(`${data.endDate.month} 1, ${endYear}`).getMonth();

      if (!(endYear > startYear || (endYear === startYear && endMonth >= startMonth))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'End date must be after start date',
          path: ['endDate'],
        });
      }
    } else {
      if (data.endDate === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'End date is required when not currently working',
          path: ['endDate'],
        });
      }
    }
  });

export type JobFormData = z.infer<typeof jobFormSchema>;

// WorkHistory {
//     id: string;
//     title: string;
//     description: string;
//     company_name: string;
//     start_date: string;
//     end_date: string | null;
//     is_current: boolean;
//   }

export function mapWorkFormDataToApi(formData: JobFormData): WorkHistory {
  return {
    id: formData.id ?? new Date().getTime().toString(),
    company_name: formData.companyName,
    description: formData.description,
    end_date: formData.isCurrentlyWorking
      ? null
      : formData.endDate
      ? `${formData.endDate.year}-${String(formData.endDate.month).padStart(2, '0')}`
      : null,
    is_current: formData.isCurrentlyWorking ?? false,
    start_date: `${formData.startDate.year}-${String(formData.startDate.month).padStart(2, '0')}`,
    title: formData.title,
  };
}

export function mapApiDataToWorkForm(apiData: WorkHistory): JobFormData {
  const parseMonth = (dateStr?: string) => Number(dateStr?.split('-')[1] ?? '1');
  const parseYear = (dateStr?: string) =>
    Number(dateStr?.split('-')[0]) ?? new Date().getFullYear();

  return {
    id: apiData.id,
    companyName: apiData.company_name,
    description: apiData.description,
    isCurrentlyWorking: apiData.is_current,
    startDate: {
      month: parseMonth(apiData.start_date),
      year: parseYear(apiData.start_date),
    },
    endDate: apiData.is_current
      ? undefined
      : {
          month: parseMonth(apiData.end_date ?? undefined),
          year: parseYear(apiData.end_date ?? undefined),
        },
    title: apiData.title,
  };
}

export default jobFormSchema;
