import React, { useRef } from 'react';
import { UserAvatar } from 'src/app/components/common/user-avatar';
import { Button } from 'src/app/components/ui/button';
import { Input } from 'src/app/components/ui/input';
import { useAuth } from 'src/app/modules/auth';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/app/components/ui/form';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from 'src/app/components/ui/select';
import { useEditUserContext } from './context';
import { Combobox } from 'src/app/components/ui/combobox';
import { useSidebar, SIDEBAR_WIDTH, SIDEBAR_WIDTH_ICON } from 'src/app/components/ui/sidebar';
import { cn } from 'src/lib/utils';
import MultiTagInput from 'src/app/components/common/multitag-input';
import RichTextEditor from 'src/app/components/common/rich-text-editor';
import { Info, Trash } from 'lucide-react';
import { HoverCard, HoverCardContent, HoverCardTrigger } from 'src/app/components/ui/hover-card';

const EditUserContent = () => {
  const { open: isSidebarOpen } = useSidebar();
  const imageInputRef = useRef<HTMLInputElement>(null);
  const {
    profile,
    form,
    countries,
    isUS,
    setIsUS,
    states,
    timezones,
    salaryRanges,
    startAvailabilities,
    yearsOfExperience,
    handleProfileSubmit,
    imageUrl,
    handleImageUpload,
    removeImage,
    defaultImage,
  } = useEditUserContext();

  return (
    <div>
      <div className="tw-flex tw-items-center tw-justify-start tw-w-full tw-mb-6">
        <UserAvatar
          src={imageUrl ?? undefined}
          alt={`${profile?.first_name || ''} ${profile?.last_name || ''}`}
          initials={`${profile?.first_name?.charAt(0) || ''}${profile?.last_name?.charAt(0) || ''}`}
          size={104}
        />
        <Button
          variant="outline"
          className="tw-ml-6"
          onClick={() => imageInputRef.current?.click()}
        >
          Upload Image
        </Button>
        <Button disabled={defaultImage} variant="ghost" className="tw-ml-4" onClick={removeImage}>
          <Trash className="tw-w-4 tw-h-4" />
        </Button>
        <input
          type="file"
          className="tw-hidden"
          ref={imageInputRef}
          accept="image/*"
          onChange={(e) => {
            if (e.target.files?.[0]) {
              handleImageUpload(e.target.files?.[0] as File);
            }
          }}
        />
      </div>
      <Form {...form}>
        <form
          onSubmit={(d) => {
            console.log('d', d);
            form.handleSubmit(handleProfileSubmit)(d);
          }}
          className="tw-grid tw-grid-cols-2 tw-gap-6"
        >
          <FormField
            control={form.control}
            name="full_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="required">Full Name</FormLabel>
                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  Your legal first and last name
                </FormDescription>
                <FormControl>
                  <Input {...field} placeholder="John Doe" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Salary Range */}
          <FormField
            control={form.control}
            name="salary_range_in_cents"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="required">Salary Range</FormLabel>
                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  Monthly salary request in USD for full-time work
                </FormDescription>
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger value={field.value}>
                        <SelectValue placeholder="Select Salary Range" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {salaryRanges.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Headline */}
          <FormField
            control={form.control}
            name="headline"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="required">Headline</FormLabel>
                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  A brief professional title that describes your role (e.g., "Senior Full Stack
                  Developer")
                </FormDescription>
                <FormControl>
                  <Input {...field} maxLength={30} placeholder="Senior Full Stack Developer" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Location Fields */}
          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="required">Country</FormLabel>
                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  Make country selection
                </FormDescription>
                <FormControl>
                  <Combobox
                    options={countries}
                    value={field.value}
                    onChange={(value) => {
                      setIsUS(value === 'United States');
                      field.onChange(value);
                    }}
                    placeholder="United States"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Conditional US Location Fields */}
          {isUS && (
            <>
              <div></div>
              <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <FormField
                  control={form.control}
                  name="state"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="required">State</FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <SelectTrigger value={field.value}>
                            <SelectValue placeholder="California" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {states.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="city"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="required">City</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="San Francisco" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </>
          )}

          {/* Timezone */}
          <FormField
            control={form.control}
            name="timezone"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="required">Timezone</FormLabel>
                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  Your current primary working timezone
                </FormDescription>
                <FormControl>
                  <Combobox
                    options={timezones}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="(UTC-08:00) Pacific Standard Time (US & Canada)"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Years of Experience */}
          <FormField
            control={form.control}
            name="years_professional_experience"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="required">Professional Years Experience</FormLabel>
                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  Total years of professional work experience in your field
                </FormDescription>
                <FormControl>
                  <Combobox
                    options={yearsOfExperience}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    placeholder="3"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Start Availability */}
          <FormField
            control={form.control}
            name="start_availability"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="required">Start Availability</FormLabel>
                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  When you can start a new position
                </FormDescription>

                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger value={field.value}>
                      <SelectValue placeholder="2 Weeks Notice" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {startAvailabilities.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="tw-col-span-2">
            {/* Bio */}
            <FormField
              control={form.control}
              name="bio"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="required">Bio</FormLabel>
                  <div className="tw-flex tw-justify-between tw-items-center !tw-mt-0">
                    <FormDescription className="tw-text-xs tw-text-gray-dark">
                      Tell us about yourself. What do you enjoy working on?
                    </FormDescription>
                    <div className="tw-text-xs tw-text-primary-black tw-font-semibold">
                      Max character count 1000.
                    </div>
                  </div>
                  <FormControl>
                    <RichTextEditor
                      value={field.value as string}
                      defaultValue={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* Main Skills */}
          <FormField
            control={form.control}
            name="skills"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="">
                  <span className="required tw-inline-flex">Main Skills</span>
                  <HoverCard>
                    <HoverCardTrigger asChild>
                      <Info className="tw-w-3 tw-h-3 tw-inline-flex tw-ml-1" />
                    </HoverCardTrigger>
                    <HoverCardContent className="tw-w-[380px] tw-bg-primary-dark tw-text-xs tw-text-white !tw-broder-none !tw-rounded-md">
                      Please use the 'Main Skills' section to highlight the key skills you've
                      developed throughout your experiences. This should reflect the areas where you
                      have the most expertise and achievements, helping us better understand your
                      strengths.
                    </HoverCardContent>
                  </HoverCard>
                </FormLabel>
                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  Please enter min. 3 and max. 5 skills
                </FormDescription>
                <FormControl>
                  <MultiTagInput
                    tags={field.value}
                    onChange={field.onChange}
                    placeholder="React, TypeScript, Node.js"
                    maxTags={5}
                    caseSensitive={false}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Languages */}
          <FormField
            control={form.control}
            name="languages"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="required">Languages</FormLabel>
                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  Provide a list of languages you are proficient in, both written and spoken.
                </FormDescription>
                <FormControl>
                  <MultiTagInput
                    tags={field.value}
                    onChange={field.onChange}
                    placeholder="English (Native), Spanish (Fluent)"
                    caseSensitive={false}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Additional Skills */}
          <FormField
            control={form.control}
            name="additional_skills"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="">
                  <span className="required tw-inline-flex">Additional Skills</span>
                  <HoverCard>
                    <HoverCardTrigger asChild>
                      <Info className="tw-w-3 tw-h-3 tw-inline-flex tw-ml-1" />
                    </HoverCardTrigger>
                    <HoverCardContent className="tw-w-[380px] tw-bg-primary-dark tw-text-xs tw-text-white !tw-broder-none !tw-rounded-md">
                      Please use the 'Additional Skills' section to highlight other relevant skills
                      you've developed. These complement your main expertise and provide a broader
                      understanding of your abilities.
                    </HoverCardContent>
                  </HoverCard>
                </FormLabel>

                <FormDescription className="tw-text-xs tw-text-gray-dark !tw-mt-0">
                  Please enter up to 20 skills
                </FormDescription>
                <FormControl>
                  <MultiTagInput
                    tags={field.value}
                    onChange={field.onChange}
                    placeholder="Docker, AWS, GraphQL, Redux, Jest"
                    maxTags={20}
                    caseSensitive={false}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="tw-col-span-2 tw-h-[66px]"></div>
          {form.formState.isDirty ? (
            <div
              className={cn(
                `tw-fixed tw-bottom-0 tw-bg-white/50 tw-pl-4 tw-right-0 tw-col-span-2 tw-h-[90px] tw-flex tw-justify-center tw-items-center tw-shadow tw-transition-all tw-duration-300`
              )}
              style={{
                backdropFilter: 'blur(1.5px)',
                width: isSidebarOpen
                  ? `calc(100vw - ${SIDEBAR_WIDTH})`
                  : `calc(100vw - ${SIDEBAR_WIDTH_ICON})`,
              }}
            >
              <Button variant="outline" type="submit">
                Save Changes
              </Button>
            </div>
          ) : null}
        </form>
      </Form>
    </div>
  );
};

export default EditUserContent;
