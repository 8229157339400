import React from 'react';
import { TabsTrigger } from 'src/app/components/ui/tabs';
import { cn } from 'src/lib/utils';

interface Step {
  title: string;
}

interface StepperProps {
  steps: Step[];
  currentStep: number;
  stepState: {
    isCompleted: boolean;
    onClick?: () => void;
  }[];
}
const Stepper: React.FC<StepperProps> = ({ steps, currentStep, stepState }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-pb-8 tw-my-5">
      {steps.map((step, index) => {
        const isActive = index + 1 === currentStep;

        return (
          <div key={index} className="tw-flex tw-items-center">
            <div className="tw-flex tw-relative tw-flex-col tw-items-center">
              {/* Step Indicator */}
              <button
                onClick={stepState[index]?.onClick}
                className={cn(
                  'tw-flex tw-items-center tw-justify-center tw-border-primary  tw-text-primary-dark tw-w-10 tw-h-10 tw-border-2 tw-rounded-full tw-text-sm tw-font-bold',
                  {
                    'tw-border-none tw-bg-gradient-primary tw-text-white':
                      stepState[index]?.isCompleted,
                    'tw-border-primary/20': !stepState[index]?.isCompleted,
                  }
                )}
              >
                {index + 1}
              </button>

              {/* Step Title */}
              <TabsTrigger
                value={step.title}
                onClick={stepState[index]?.onClick}
                className={cn(
                  'tw-text-sm tw-font-medium tw-absolute tw-top-10 tw-mt-2.5 tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-text-center tw-text-nowrap',
                  isActive
                    ? 'tw-text-primary-dark tw-font-bold'
                    : 'tw-text-gray-light2 tw-font-medium'
                )}
              >
                {step.title}
              </TabsTrigger>
            </div>
            {/* Step Connector */}
            {index !== steps.length - 1 && (
              <div
                className={cn('tw-flex-1 tw-w-[194px] tw-h-0.5 tw-mx-2.5', {
                  'tw-bg-stroke-light': !stepState[index]?.isCompleted,
                  'tw-bg-primary/20': stepState[index]?.isCompleted,
                })}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
