import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { ATSContextValue, ATSCandidate, InterviewStatus, FormValues, formSchema } from './types';
import {
  getCandidateByToken,
  InterviewDetails,
  saveATSInterviewVideo,
  createATSInterviewByToken,
  getATSInterviewById,
  updateCandidateAvailabilityAndSalaryByToken,
  saveATSCandidateResume,
  getATSCandidateStatus,
} from 'src/app/api/ats/service';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useToast } from 'src/app/hooks/use-toast';
const ATSContext = createContext<ATSContextValue | undefined>(undefined);

export const useATS = () => {
  const context = useContext(ATSContext);
  if (context === undefined) {
    throw new Error('useATS must be used within an ATSProvider');
  }
  return context;
};

export const ATSProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { token: tokenParam } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showAvatar, setShowAvatar] = useState(false);
  const [token, setToken] = useState<string | null>(tokenParam || null);
  const [candidateId, setCandidateId] = useState<string | null>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isInterviewReady, setIsInterviewReady] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [interviewStatus, setInterviewStatus] = useState<InterviewStatus>('pending');
  const [cancelAttempts, setCancelAttempts] = useState(0);
  const [interviewId, setInterviewId] = useState<string | null>(null);
  const [interview, setInterview] = useState<InterviewDetails | null>(null);
  const [isInterviewLoading, setIsInterviewLoading] = useState(false);
  const [interviewError, setInterviewError] = useState<Error | null>(null);
  const [resume, setResume] = useState<File | null>(null);
  const [isChrome, setIsChrome] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isInterviewExpired, setIsInterviewExpired] = useState(false);
  const [questionsAnswered, setQuestionsAnswered] = useState<boolean[]>([]);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      salary_range_in_cents: '',
      availability: '',
      start_availability: '',
    },
  });

  const navigateToPreviewUser = useCallback(() => {
    if (!tokenParam) return;
    navigate(`/ats-interview/${tokenParam}`);
  }, [navigate, tokenParam]);

  const navigateToPreviewResume = useCallback(() => {
    if (!tokenParam) return;
    navigate(`/ats-interview/${tokenParam}/preview-resume`);
  }, [navigate, tokenParam]);

  const navigateToCheckCamera = useCallback(() => {
    if (!tokenParam) return;
    navigate(`/ats-interview/${tokenParam}/check-camera`);
  }, [navigate, tokenParam]);

  const navigateToInterview = useCallback(() => {
    if (!tokenParam) return;
    navigate(`/ats-interview/${tokenParam}/interview`);
  }, [navigate, tokenParam]);

  const navigateToComplete = useCallback(() => {
    if (!tokenParam) return;
    navigate(`/ats-interview/${tokenParam}/complete`);
  }, [navigate, tokenParam]);

  const {
    data: candidate,
    isLoading,
    error: candidateError,
  } = useQuery<ATSCandidate | null, Error>(
    ['candidate', tokenParam],
    () => getCandidateByToken(tokenParam!),
    {
      enabled: !!tokenParam,
    }
  );

  const createInterview = useCallback(async () => {
    if (!tokenParam || !isConfirmed) return;

    setIsInterviewLoading(true);
    try {
      const { id: newInterviewId, status, message } = await createATSInterviewByToken(tokenParam!);
      if (status === 'error') {
        setInterviewError(new Error(message));
        return null;
      } else {
        setInterviewId(newInterviewId);
        return newInterviewId;
      }
    } catch (error) {
      setInterviewError(error as Error);
      return null;
    } finally {
      setIsInterviewLoading(false);
    }
  }, [tokenParam, isConfirmed]);

  const fetchInterview = useCallback(async (id: string) => {
    setIsInterviewLoading(true);
    try {
      const interviewData = await getATSInterviewById(id);
      setInterview(interviewData);
      // Find the first unanswered question index
      const firstUnansweredIndex = interviewData.questions.findIndex(
        (question) => !question.answer || question.answer === ''
      );
      if (firstUnansweredIndex !== -1) {
        setCurrentQuestion(firstUnansweredIndex + 1);
      } else {
        setInterviewStatus('completed');
        navigateToComplete();
      }
      setQuestionsAnswered(interviewData.questions.map((question) => !!question.answer));
      return interviewData;
    } catch (error) {
      setInterviewError(error as Error);
      return null;
    } finally {
      setIsInterviewLoading(false);
    }
  }, []);

  const nextQuestion = useCallback(() => {
    if (interview && currentQuestion >= interview.questions.length) {
      // TODO: refetch interview and make sure all questions are answered
      setInterviewStatus('completed');
      navigateToComplete();
      return;
    }
    setCurrentQuestion((prev) => prev + 1);
  }, [interview, currentQuestion]);

  const saveVideo = useCallback(
    async (questionIndex: number, file: File, transcription: string) => {
      try {
        if (!interview || !tokenParam) return;

        // TODO: Implement the actual API call to save the video
        // This is a placeholder for the actual implementation
        await saveATSInterviewVideo({
          questionId: interview.questions[questionIndex].questionId,
          file,
          token: tokenParam,
          transcription,
        });

        return true;
      } catch (error) {
        console.error('Error saving video:', error);
        return false;
      }
    },
    [interview, tokenParam]
  );

  const cancelInterview = useCallback(() => {
    setCancelAttempts((prev) => {
      const newAttempts = prev + 1;

      setCurrentQuestion(1);
      setIsInterviewReady(false);

      // Reset everything only on the third attempt
      if (newAttempts >= 3) {
        // Reset all state to initial values
        setCandidateId(null);
        setIsConfirmed(false);
        setInterviewStatus('cancelled');

        // Navigate back to the starting point
        navigateToPreviewUser();
        return 0;
      }

      return newAttempts;
    });
  }, [navigate]);

  const pollInterviewStatus = useCallback(
    async (id: string, maxAttempts = 30, intervalMs = 2000) => {
      console.log('Starting interview status polling...');
      let attempts = 0;

      const poll = async (): Promise<InterviewDetails | null> => {
        try {
          const interviewData = await fetchInterview(id);
          console.log('Poll attempt', attempts + 1, 'status:', interviewData?.status);

          if (!interviewData) {
            throw new Error('Failed to fetch interview data');
          }

          // Check if we've reached a final status
          if (interviewData.status === 'completed' || interviewData.status === 'failed') {
            console.log('Interview reached final status:', interviewData.status);
            return interviewData;
          }

          // Check if we've exceeded max attempts
          if (++attempts >= maxAttempts) {
            console.log('Max polling attempts reached');
            throw new Error('Interview status polling timeout');
          }

          // Wait for the interval and try again
          await new Promise((resolve) => setTimeout(resolve, intervalMs));
          return poll();
        } catch (error) {
          console.error('Polling error:', error);
          setInterviewError(error as Error);
          return null;
        }
      };

      return poll();
    },
    [fetchInterview]
  );

  const onFormSubmit = async (values: FormValues) => {
    if (
      values.salary_range_in_cents === candidate?.salary_range_in_cents &&
      values.availability === candidate?.availability &&
      values.start_availability === candidate?.start_availability
    ) {
      console.log('No changes');
      navigateToPreviewResume();
      return;
    }
    try {
      setIsInterviewLoading(true);
      await updateCandidateAvailabilityAndSalaryByToken(tokenParam!, values);
      toast({
        title: 'Success',
        description: 'Candidate details updated successfully',
      });
      navigateToPreviewResume();
    } catch (error) {
      console.error('Failed to save details:', error);
    } finally {
      setIsInterviewLoading(false);
    }
  };

  const handleQuestionAnswered = (questionIndex: number, isAnswered: boolean) => {
    setQuestionsAnswered((prev) => {
      const newQuestionsAnswered = [...prev];
      newQuestionsAnswered[questionIndex] = isAnswered;
      return newQuestionsAnswered;
    });
  };

  const handleResumeUpload = async () => {
    if (!resume) return;

    setIsInterviewLoading(true);
    try {
      const resumeUrl = await saveATSCandidateResume({
        file: resume,
        candidateId: candidateId!,
        token: tokenParam!,
      });

      if (resumeUrl) {
        toast({
          title: 'Success',
          description: 'Resume uploaded successfully',
        });
        navigateToCheckCamera();
      } else {
        toast({
          title: 'Error',
          description: 'Failed to upload resume',
          variant: 'destructive',
        });
      }
    } catch (error) {
      console.error('Resume upload error:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to upload resume',
        variant: 'destructive',
      });
    } finally {
      setIsInterviewLoading(false);
    }
  };

  const handleSkipResumeUpload = async () => {
    navigateToCheckCamera();
  };

  useEffect(() => {
    if (candidate) {
      setCandidateId(candidate.id);
      const updateCandidateStatus = async () => {
        const status = await getATSCandidateStatus(tokenParam!);
        if (status.is_ok) {
          setIsDone(false);
        } else if (status.error === 'existing') {
          setIsDone(true);
        } else if (status.error === 'expired') {
          setIsInterviewExpired(true);
        }
        setIsInterviewLoading(false);
      };
      setIsInterviewLoading(true);
      updateCandidateStatus();
      form.reset({
        salary_range_in_cents: candidate.salary_range_in_cents,
        availability: candidate.availability,
        start_availability: candidate.start_availability,
      });
    }
  }, [candidate]);

  useEffect(() => {
    if (interviewError) {
      toast({
        title: 'Error',
        description: interviewError.message,
        variant: 'destructive',
      });
    }
  }, [interviewError]);

  useEffect(() => {
    if (!tokenParam) return;
    const currentPage = window.location.pathname;
    if (!isConfirmed && currentPage !== `/ats-interview/${tokenParam}`) {
      navigateToPreviewUser();
    }
  }, [isConfirmed, tokenParam, navigateToPreviewUser]);

  useEffect(() => {
    const userAgent = navigator?.userAgent;
    const isChromeBrowser = /Chrome/.test(userAgent);
    setIsChrome(isChromeBrowser);
  }, []);

  const value: ATSContextValue = {
    candidateId,
    candidate,
    interview: interview || null,
    interviewId: interviewId || null,
    error: candidateError || interviewError,
    isLoading: isLoading || isInterviewLoading,
    token: token || tokenParam!,
    setToken,
    setCandidateId,
    showAvatar,
    setShowAvatar,
    isConfirmed,
    setIsConfirmed,
    isInterviewReady,
    setIsInterviewReady,
    currentQuestion,
    nextQuestion,
    interviewStatus,
    setInterviewStatus,
    cancelInterview,
    saveVideo,
    cancelAttempts,
    createInterview,
    fetchInterview,
    pollInterviewStatus,
    form,
    onFormSubmit,
    handleResumeUpload,
    handleSkipResumeUpload,
    setResume,
    resume,
    isChrome,
    isDone,
    isInterviewExpired,
    questionsAnswered,
    handleQuestionAnswered,
    navigateToCheckCamera,
    navigateToPreviewResume,
    navigateToComplete,
    navigateToInterview,
    navigateToPreviewUser,
  };

  return <ATSContext.Provider value={value}>{children}</ATSContext.Provider>;
};
