import { candidateAPI } from '.';
import { uploadToS3WithPresignedUrl } from '../aws/s3';
import { Candidate } from './types';

export const getCandidateProfile = async (): Promise<Candidate> => {
  const response = await candidateAPI.get('/profile');
  return response.data.value as Candidate;
};

export const updateCandidateProfile = async (data: any) => {
  const { skills, additional_skills, languages, ...rest } = data;
  const response = await candidateAPI.post('/profile', {
    ...rest,
    skills: skills.join(','),
    additional_skills: additional_skills.join(','),
    languages: languages.join(','),
  });
  return response.data.value;
};

export const updateProfileAvatar = async (avatarLoc: string | null) => {
  const response = await candidateAPI.patch('/profile/avatar', { location: avatarLoc });
  return response.data.value;
};

export const deleteProfileImage = async () => {
  const response = await candidateAPI.delete('/profile/avatar');
  return response.data.value;
};

export const updateProfileImage = async (file: File): Promise<string | null> => {
  try {
    const response = await uploadToS3WithPresignedUrl(file, file.name);
    if (response.success) {
      await updateProfileAvatar(response.data?.fullLocation);
      return response.data?.fullLocation;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const uploadCandidateResume = async (file: File) => {
  // Validate file size (5MB limit)
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
  if (file.size > MAX_FILE_SIZE) {
    throw new Error('File size exceeds 5MB limit');
  }

  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file.name);

  const response = await candidateAPI.post('/profile/resume', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return response.data.value;
};

export const parseResume = async (file: File) => {
  // Validate file size (5MB limit)
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
  if (file.size > MAX_FILE_SIZE) {
    throw new Error('File size exceeds 5MB limit');
  }

  const formData = new FormData();
  formData.append('resume', file);
  formData.append('fileName', file.name);
  const response = await candidateAPI.post('/profile/resume/parse', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return response.data.value;
};
