import { AppSidebar } from './app-sidebar';

import { SidebarInset, SidebarProvider } from 'src/app/components/ui/sidebar';
import { Outlet } from 'react-router-dom';
import { useAuth } from 'src/app/modules/auth';

export default function Sidebar() {
  const { accountType, currentUser } = useAuth();
  return (
    <SidebarProvider>
      <AppSidebar accountType={accountType} isSuperAdmin={currentUser?.superadmin} />
      <SidebarInset>
        {/* <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-data-[collapsible=icon]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            
            <Separator orientation="vertical" className="mr-2 data-[orientation=vertical]:h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem className="hidden md:block">
                  <BreadcrumbLink href="#">Building Your Application</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="hidden md:block" />
                <BreadcrumbItem>
                  <BreadcrumbPage>Data Fetching</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header> */}
        {/* <SidebarTrigger className="-ml-1" /> */}
        <div className="tw-container tw-py-6">
          <Outlet />
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}
