import { z } from 'zod';

const certificationFormSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, 'Certification name is required'),
  certificateId: z.string().optional(),
  dateAcquired: z
    .object({
      month: z.number().min(1, 'Invalid month').max(12, 'Invalid month'),
      year: z
        .number()
        .min(1900, 'Invalid year')
        .max(new Date().getFullYear(), 'Acquisition year cannot be in the future'),
    })
    .optional(),
});

export type CertificationFormData = z.infer<typeof certificationFormSchema>;

export interface ApiCertificationData {
  id: string;
  name: string;
  certificate_id: string | null;
  date_acquired: string | null;
}

export function mapCertificationFormDataToApi(
  formData: CertificationFormData
): ApiCertificationData {
  return {
    id: formData.id ?? new Date().getTime().toString(),
    name: formData.name,
    certificate_id: formData.certificateId || null,
    date_acquired: formData.dateAcquired
      ? `${formData.dateAcquired.year}-${String(formData.dateAcquired.month).padStart(2, '0')}`
      : null,
  };
}

export function mapApiDataToCertificationForm(
  apiData: ApiCertificationData
): CertificationFormData {
  const parseMonth = (dateStr: string) => Number(dateStr.split('-')[1]);
  const parseYear = (dateStr: string) => Number(dateStr.split('-')[0]);

  return {
    id: apiData.id,
    name: apiData.name,
    certificateId: apiData.certificate_id || undefined,
    dateAcquired: apiData.date_acquired
      ? {
          month: parseMonth(apiData.date_acquired),
          year: parseYear(apiData.date_acquired),
        }
      : undefined,
  };
}

export default certificationFormSchema;
