import { Education } from 'src/app/api/candidate/types';
import { z } from 'zod';

const educationFormSchema = z
  .object({
    id: z.string().optional(),
    institution: z.string().min(1, 'Institution is required'),
    degree: z.string().min(1, 'Degree is required'),
    description: z.string().min(1, 'Description is required'),
    isCurrentlyStudying: z.boolean().optional(),
    startDate: z.object({
      month: z.number().min(1, 'Invalid month').max(12, 'Invalid month'),
      year: z
        .number()
        .min(1900, 'Invalid year')
        .max(new Date().getFullYear(), 'Start year cannot be in the future'),
    }),
    endDate: z
      .object({
        month: z.number().min(1, 'Invalid month').max(12, 'Invalid month'),
        year: z
          .number()
          .min(1900, 'Invalid year')
          .max(new Date().getFullYear(), 'End year cannot be in the future'),
      })
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.endDate === undefined && !data.isCurrentlyStudying) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'End date is required when not currently studying',
        path: ['endDate'],
      });
      return;
    }

    if (data.endDate) {
      // Validate that end date is after start date
      const startYear = data.startDate.year;
      const startMonth = new Date(`${data.startDate.month} 1, ${startYear}`).getMonth();
      const endYear = data.endDate.year;
      const endMonth = new Date(`${data.endDate.month} 1, ${endYear}`).getMonth();

      if (!(endYear > startYear || (endYear === startYear && endMonth >= startMonth))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'End date must be after start date',
          path: ['endDate'],
        });
      }
    }
  });

export type EducationFormData = z.infer<typeof educationFormSchema>;

export function mapEducationFormDataToApi(formData: EducationFormData): Education {
  return {
    id: formData.id ?? new Date().getTime().toString(),
    institution: formData.institution,
    degree: formData.degree,
    description: formData.description,
    end_date: formData.isCurrentlyStudying
      ? null
      : formData.endDate
      ? `${formData.endDate.year}-${String(formData.endDate.month).padStart(2, '0')}`
      : null,
    is_current: formData.isCurrentlyStudying ?? false,
    start_date: `${formData.startDate.year}-${String(formData.startDate.month).padStart(2, '0')}`,
  };
}

export function mapApiDataToEducationForm(apiData: Education): EducationFormData {
  const parseMonth = (dateStr?: string) => Number(dateStr?.split('-')[1] ?? '1');
  const parseYear = (dateStr?: string) =>
    Number(dateStr?.split('-')[0]) ?? new Date().getFullYear();

  return {
    id: apiData.id,
    institution: apiData.institution,
    degree: apiData.degree,
    description: apiData.description ?? '',
    isCurrentlyStudying: apiData.is_current,
    startDate: {
      month: parseMonth(apiData.start_date),
      year: parseYear(apiData.start_date),
    },
    endDate: apiData.is_current
      ? undefined
      : {
          month: parseMonth(apiData.end_date ?? undefined),
          year: parseYear(apiData.end_date ?? undefined),
        },
  };
}

export default educationFormSchema;
