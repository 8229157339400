import { ATSCandidate, FormValues } from 'src/app/modules/ats/context/types';
import { atsAPI, atsInterviewAPI } from '.';
import { Config } from 'src/config';
import { ApiResponse, ApiResponseNonArray } from '../types';

export interface ATSCandidateProfile {
  id: string;
  user_id: string;
  headline: string;
  timezone: string | null;
  location: string;
  state: string | null;
  city: string | null;
  years_professional_experience: string;
  skills: string[];
  bio: string;
  salary_range_cents_min: number;
  salary_range_cents_max: number;
  availability: string;
  languages: string[];
  crm_id: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  additional_skills: string[];
  start_availability: string;
  resume_url: string;
  location_country: string | null;
}
export interface ATSJob {
  id: string;
  organization_id: string;
  title: string;
  skills: string[];
  description: string;
  job_role_id: string | null;
  timezone: string;
  salary_range_cents_max: number;
  salary_range_cents_min: number;
  commitment_type: string;
  status: string;
  open_roles: number;
  created_by: string | null;
  pay_frequency: string;
  additional_skills: string[];
  skills_seniority: string | null;
  key_questions: string | null;
  years_experience: string | null;
  regions: string | null;
  work_model: string | null;
  state: string | null;
  city: string | null;
  kombo_job_id: string;
  assignee: string;
  salary_range_dollars_min: string;
  salary_range_dollars_max: string;
}

export interface ATSProfile {
  id: string;
  user_type: string;
  first_name: string;
  last_name: string;
  email: string;
  slug: string;
  avatar_url: string;
  recruiter: string | null;
  desired_role_id: string | null;
  timezone: string | null;
  created_at: string;
  verification_token: string | null;
  last_activity: string | null;
  job_application_id: string;
  kombo_id: string;
  kombo_integration_id: string;
  ats_email_date_sent: string;
  full_name: string;
  initials: string;
  first_name_last_name_initial: string;
  user_hash: string;
}

export interface ATSCandidateProfileResponse extends ATSProfile {
  job: ATSJob;
  candidate_profile: ATSCandidateProfile | null;
}

export const getCandidateByToken = async (token: string): Promise<ATSCandidate | null> => {
  try {
    const {
      data: { value },
    } = await atsAPI.get<ApiResponse<ATSCandidateProfileResponse>>(`/candidate/${token}`);
    const data = value[0];
    return {
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      jobTitle: data.job.title,
      avatar: '',
      salary_range_in_cents: data.candidate_profile?.salary_range_cents_min
        ? `${data.candidate_profile?.salary_range_cents_min}-${data.candidate_profile?.salary_range_cents_max}`
        : '',
      availability: data.candidate_profile?.availability ?? '',
      start_availability: data.candidate_profile?.start_availability ?? '',
      resume_url: data.candidate_profile?.resume_url ?? '',
    };
  } catch (error) {
    // ... error handling ...
    console.error('getCandidateByToken error', error);
    return null;
  }
};

// Types for better type safety
interface CreateInterviewResponse {
  ats_candidate_id: string;
  status: string;
  total_score: number;
  job_id: string;
  id: string;
}

// Internal types (camelCase)
interface InterviewQuestionResponse {
  id: string;
  ats_interview_id: string;
  question: string;
  question_title: string;
  type: string;
  answer: string | null;
  score: number;
  description: string | null;
  data: any | null;
}

interface InterviewDetailsResponse {
  id: string;
  ats_candidate_id: string;
  status: 'pending' | 'scoring' | 'completed' | 'failed';
  total_score: number;
  total_score_description: string | null;
  total_soft_skill_score: number | null;
  total_profile_score: number | null;
  total_hard_skills_score: number | null;
  job_id: string;
  questions: InterviewQuestionResponse[];
}

// External types (camelCase for frontend use)
export interface InterviewQuestion {
  questionId: string;
  question: string;
  questionTitle: string;
  answer: string | null;
}

export interface InterviewDetails {
  id: string;
  questions: InterviewQuestion[];
  status: InterviewDetailsResponse['status'];
  totalScore: number;
  totalScoreDescription: string | null;
  totalSoftSkillScore: number | null;
  totalProfileScore: number | null;
  totalHardSkillsScore: number | null;
}

// Payload types
export interface SubmitAnswerPayload {
  questionId: string;
  path: string;
  convertToMp4: boolean;
  transcription: string;
}

interface SubmitAnswerRequest {
  question_id: string;
  path: string;
  convert_to_mp4: boolean;
  transcription: string;
}

// Create a new ATS Interview
export const createATSInterviewByToken = async (
  token: string
): Promise<{
  id: string;
  status: 'success' | 'error';
  message: string;
}> => {
  try {
    const { data } = await atsInterviewAPI.post<ApiResponse<CreateInterviewResponse>>('/create', {
      candidate_or_token: token,
    });
    if (data.status === 'error') {
      return {
        id: '',
        status: 'error',
        message: data.message,
      };
    }
    return {
      id: data.value[0].id, // '9e5244f5-64b6-493b-a2c4-b35fb469e7d9', //
      status: 'success',
      message: 'Interview created successfully',
    };
  } catch (error: any) {
    console.error('createATSInterviewByCandidateId error', error);
    return {
      id: '',
      status: 'error',
      message: error?.response?.data?.message || 'Failed to create interview',
    };
  }
};

// Get interview details including questions
export const getATSInterviewById = async (aiInterviewId: string): Promise<InterviewDetails> => {
  const {
    data: { value },
  } = await atsInterviewAPI.get<ApiResponse<InterviewDetailsResponse>>(`/view/${aiInterviewId}`);
  const data = value[0];
  return {
    id: data.id,
    questions: data.questions.map((q) => ({
      questionId: q.id,
      question: q.question,
      questionTitle: q.question_title,
      answer: q.answer,
    })),
    status: data.status,
    totalScore: data.total_score,
    totalScoreDescription: data.total_score_description,
    totalSoftSkillScore: data.total_soft_skill_score,
    totalProfileScore: data.total_profile_score,
    totalHardSkillsScore: data.total_hard_skills_score,
  };
};

// Submit answer for a question
export const submitATSInterviewAnswer = async (payload: SubmitAnswerPayload): Promise<void> => {
  const requestPayload: SubmitAnswerRequest = {
    question_id: payload.questionId,
    path: payload.path,
    convert_to_mp4: payload.convertToMp4,
    transcription: payload.transcription,
  };

  await atsInterviewAPI.post('/video', requestPayload);
};

// Create interview and get questions in one operation
export const createAndGetATSInterview = async (candidateId: string): Promise<InterviewDetails> => {
  // First create the interview and get the ID
  const interviewId = '9e1f6991-dc7b-4e6d-b1ec-81d95e297f03'; //await createATSInterviewByCandidateId(candidateId);

  // Then fetch the interview details including questions
  return await getATSInterviewById(interviewId);
};

interface SaveVideoPayload {
  questionId: string;
  file: File;
  token: string;
  transcription: string;
  interviewType?: string;
}

export const saveATSInterviewVideo = async (payload: SaveVideoPayload): Promise<void> => {
  const { questionId, file, token, transcription, interviewType = 'ats' } = payload;
  // Create custom filename
  const customFileName = `${interviewType}-question-${questionId}-user-${token}_${new Date().getTime()}`;
  const fullLocation = `https://${Config.services.aws.bucket}.s3.amazonaws.com/${customFileName}`;

  try {
    // Get presigned URL
    const s3response = await atsInterviewAPI.post(`/save`, {
      candidate_or_token: token,
      questionId,
      fileName: customFileName,
      fileType: file.type,
    });
    const presignedUrl = s3response?.data?.url;

    try {
      // Upload to S3
      await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
    } catch (uploadError) {
      console.error('Error uploading to S3:', uploadError);
    }
  } catch (presignedUrlError) {
    console.error('Error getting presigned URL:', presignedUrlError);
  }

  // Continue with submitting the answer regardless of S3 upload success
  await submitATSInterviewAnswer({
    questionId,
    path: fullLocation,
    convertToMp4: true,
    transcription,
  });
};

interface AudioResponse {
  value: {
    file: string; // base64 encoded audio file
  };
}

export const getQuestionAudio = async (text: string): Promise<HTMLAudioElement> => {
  const response = await atsInterviewAPI.get<AudioResponse>(`/audio`, {
    params: {
      text,
    },
  });

  // Convert base64 to Audio object
  const byteCharacters = atob(response.data.value.file);
  const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'audio/mpeg' });
  const url = URL.createObjectURL(blob);

  return new Audio(url);
};

export const updateCandidateAvailabilityAndSalaryByToken = async (
  token: string,
  form: FormValues
): Promise<void> => {
  await atsAPI.patch(`/candidate/${token}`, form);
};

export const updateCandidateResumeByToken = async (
  token: string,
  resumeUrl: string
): Promise<void> => {
  await atsAPI.patch(`/candidate/${token}`, { resume_url: resumeUrl });
};

interface SaveResumePayload {
  file: File;
  candidateId: string;
  token: string;
}

export const saveATSCandidateResume = async (payload: SaveResumePayload): Promise<string> => {
  const { file, candidateId, token } = payload;

  // Validate file type
  const allowedTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  if (!allowedTypes.includes(file.type)) {
    throw new Error('Invalid file type. Only PDF and DOC files are allowed.');
  }

  // Create custom filename
  const customFileName = `resume-user-${candidateId}_${new Date().getTime()}`;

  try {
    // Get presigned URL
    const s3response = await atsAPI.post(`/candidate/resume`, {
      candidate_or_token: token,
      fileName: customFileName,
      fileType: file.type,
    });
    const presignedUrl = s3response?.data?.value?.url;

    // Upload to S3
    const uploadResponse = await fetch(presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    // Verify the upload was successful
    if (!uploadResponse.ok) {
      throw new Error(`Failed to upload file: ${uploadResponse.statusText}`);
    }
    const fullLocation = `https://${Config.services.aws.bucket}.s3.amazonaws.com/${
      s3response?.data?.value?.key ?? ''
    }`;

    // Only update the resume URL if the upload was successful
    await updateCandidateResumeByToken(token, fullLocation);
    return fullLocation;
  } catch (error) {
    console.error('Error in saveATSCandidateResume:', error);
    throw new Error('Failed to upload and save resume');
  }
};

export interface CandidateStatus {
  is_ok: boolean;
  error: 'existing' | 'expired';
}

export const getATSCandidateStatus = async (token: string) => {
  try {
    const { data } = await atsAPI.get<ApiResponseNonArray<CandidateStatus>>(
      `/candidate/check/${token}`
    );
    return data.value;
  } catch (error) {
    console.error('Error getting candidate status', error);
    return {
      is_ok: false,
      error: 'expired',
    };
  }
};
