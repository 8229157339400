import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'src/app/components/ui/alert-dialog';
import { Button } from 'src/app/components/ui/button';
import { Trash2 } from 'lucide-react';

interface DeleteModalProps {
  onDelete: () => void;
  title: string;
  description: string;
}

function DeleteModal({ onDelete, title, description }: DeleteModalProps) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="ghost" size="icon">
            <Trash2 className="tw-w-4 tw-h-4 tw-text-gray-light2" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{title}</AlertDialogTitle>
            <AlertDialogDescription>{description}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={onDelete}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export const WorkHistoryDeleteModal = ({ onDelete }: { onDelete: () => void }) => {
  return (
    <DeleteModal
      onDelete={onDelete}
      title="Delete Work History"
      description="Are you sure you want to delete this work history?"
    />
  );
};

export const EducationHistoryDeleteModal = ({ onDelete }: { onDelete: () => void }) => {
  return (
    <DeleteModal
      onDelete={onDelete}
      title="Delete Education History"
      description="Are you sure you want to delete this education history?"
    />
  );
};

export const CertificationHistoryDeleteModal = ({ onDelete }: { onDelete: () => void }) => {
  return (
    <DeleteModal
      onDelete={onDelete}
      title="Delete Certification History"
      description="Are you sure you want to delete this certification history?"
    />
  );
};

export default DeleteModal;
