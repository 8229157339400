import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeModeProvider } from '../partials';
import { PageDataProvider } from './core';
import { reInitMenu } from '../helpers';
import SidebarV2 from 'src/app/components/layout/sidebar/page';

const MasterLayout = () => {
  const location = useLocation();
  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  // if (accountType === UserTypeEnum.CANDIDATE) {
  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <SidebarV2 />
      </ThemeModeProvider>
    </PageDataProvider>
  );
  // }

  // return (
  //   <PageDataProvider>
  //     <ThemeModeProvider>
  //       <div className="d-flex flex-column flex-root app-root" id="distro_app_root">
  //         <AdminHeader />
  //         <div className="app-page flex-column flex-column-fluid" id="distro_app_page">
  //           <div
  //             className="app-wrapper flex-column flex-row-fluid !ml-[220px]"
  //             id="distro_app_wrapper"
  //           >
  //             <Sidebar />
  //             <div className="app-main flex-column flex-row-fluid" id="distro_app_main">
  //               {/* <CandidateHeaderNotice /> */}
  //               <div className="d-flex flex-column flex-column-fluid">
  //                 <Content>
  //                   <Outlet />
  //                 </Content>
  //               </div>
  //               {/*<FooterWrapper />*/}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </ThemeModeProvider>
  //   </PageDataProvider>
  // );
};

export { MasterLayout };
