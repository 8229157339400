import { candidateAPI } from '.';
import { Certification } from './types';
export const getCandidateCertificates = async () => {
  const response = await candidateAPI.get('/certifications');
  return response.data.value;
};

export const addCandidateCertificate = async (data: any): Promise<Certification> => {
  const response = await candidateAPI.post('/certifications', data);
  const responseData = response.data.value[0];
  const certificate: Certification = {
    id: responseData.id,
    name: responseData.name,
    certificate_id: responseData.certificate_id,
    date_acquired: responseData.date_acquired,
  };
  return certificate;
};

export const updateCandidateCertificate = async (data: any): Promise<Certification> => {
  const response = await candidateAPI.put(`/certifications/${data.id}`, data);
  const responseData = response.data.value[0];
  const certificate: Certification = {
    id: responseData.id,
    name: responseData.name,
    certificate_id: responseData.certificate_id,
    date_acquired: responseData.date_acquired,
  };
  return certificate;
};

export const deleteCandidateCertificate = async (id: string) => {
  const response = await candidateAPI.delete(`/certifications/${id}`);
  return response.data.value;
};
